import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { BatchDTO, BatchRecordingTypeEnum, UserExtended } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByDeliverySheetUrstammState,
  getTextByDeliverySheetUrstammState,
  getTextByDsRecordingType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterDSByNameOrCompany } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  checkIfAtLeastOneDSIsSelected,
  getCounterBySelected,
  getMandatorySymbol
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
  mainGreenButtonSelectActive
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

//Future developments
export interface BatchForm {
  batch: BatchDTO;
}

interface DeliverySheetProps {
  deliverySheetList: any[];
  deliverySheetSelected: (data: any) => void;
  resetDSSelected?: () => void;
  confirmDSSelected?: () => void;
  moreItems: () => void;
}

export default function BatchRegistrationView(props: {
  navigation: any;
  uniqueIdentifier: string;
  submitBatchForm: (data: BatchDTO) => void;
  resetBatchForm: boolean;
  recordingTypeSelected: (data: BatchRecordingTypeEnum) => void;
  userExtendedMe?: UserExtended;
  dsProps: DeliverySheetProps;
  addSingleTreeToIdentifier: (add: string) => void;
  // addDateRecordingIdentifier: (add: boolean) => void;
}) {
  const [batchForm, setBatchForm] = useStateWithCallbackLazy<BatchForm>({
    batch: {
      uniqueIdentifier: props.uniqueIdentifier ? props.uniqueIdentifier : '',
      name: '',
      creationDate: new Date(),
      recordingType: undefined,
      comment: ''
    }
  });

  const [deliverySheetList, setDeliverySheetList] = useStateWithCallbackLazy<any>(() => {});
  const [uniqueIdentifier, setUniqueIdentifier] = useState<string>(props.uniqueIdentifier);
  const [showDeliverySheetSelect, setShowDeliverySheetSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const [batchRecordingType, setBatchRecordingType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('generics.multiple'),
      enum: BatchRecordingTypeEnum.Multiple,
      active: false
    },
    {
      text: i18n.t('generics.date'),
      enum: BatchRecordingTypeEnum.Date,
      active: false
    },
    {
      text: i18n.t('generics.single'),
      enum: BatchRecordingTypeEnum.Single,
      active: false
    }
  ]);

  let keys: ListSubData[] = [
    // { title: undefined, titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'currentUrstammState', keyId: '1currentUrstammState', dataContainerViewStyle: { height: 24, flexBasis: '50%' }, isEnum: true },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.logging') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'logging',
      secondKey: 'name',
      keyId: '1logging',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'recordingType',
      keyId: '1recordingType',
      dataContainerViewStyle: { height: 24 },
      isEnum: true
    },
    {
      title: i18n.t('generics.trunks') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'numberOfTrunks',
      keyId: '1trunks',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.forestry') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'forestry',
      secondKey: 'name',
      keyId: '1forestry',
      dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    }
  ];

  useEffect(() => {
    if (props.uniqueIdentifier) {
      updateBatchForm('uniqueIdentifier', props.uniqueIdentifier);
      setUniqueIdentifier(props.uniqueIdentifier);
    }
    if (props.resetBatchForm) {
      clearForm();
      resetTreeRecordingType();
    }
  }, [props.uniqueIdentifier, props.resetBatchForm]);

  useEffect(() => {
    if (props.dsProps.deliverySheetList)
      setDeliverySheetList(props.dsProps.deliverySheetList, updatedDeliverySheetList => {
        saveButtonDisabled(errors, batchForm, updatedDeliverySheetList);
      });
  }, [props.dsProps.deliverySheetList]);

  const activeBatchRecordingType = (batchRecordingTypeEnum: BatchRecordingTypeEnum): void => {
    updateBatchForm('recordingType', batchRecordingTypeEnum);

    batchRecordingType.forEach(type => {
      type.active = type.enum == batchRecordingTypeEnum ? true : false;
    });
    setBatchRecordingType([...batchRecordingType]);
  };

  /**
   * Resetting batch form for new recording
   */
  const clearForm = () => {
    setBatchForm(
      prevState => ({
        ...prevState,
        batch: {
          uniqueIdentifier: props.uniqueIdentifier ? props.uniqueIdentifier : '',
          name: '',
          recordingType: undefined,
          comment: ''
        }
      }),
      updatedBatchForm => {
        saveButtonDisabled(errors, updatedBatchForm);
      }
    );
  };

  /**
   * Restoring initial value
   */
  const resetTreeRecordingType = (): void => {
    batchRecordingType.forEach(type => {
      type.active = false;
    });
    setBatchRecordingType([...batchRecordingType]);
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateBatchForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, batchForm, 'batch');
    setBatchForm(
      { batch: form['batch'] },
      mustValidate ? updatedBatchForm => validate(value, key, updatedBatchForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true },
    recordingType: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    recordingType: []
  });

  const validate = (value: string, key: string, updatedTreeForm: BatchForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm, deliverySheetList)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedBatchForm?: BatchForm, deliverySheetList?: any) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      !updatedBatchForm?.batch.name ||
      !updatedBatchForm?.batch.recordingType ||
      !checkIfAtLeastOneDSIsSelected(deliverySheetList)
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const setSingleTreeRecord = (enumSelect: BatchRecordingTypeEnum) => {
    if (enumSelect == BatchRecordingTypeEnum.Single) {
      // props.addDateRecordingIdentifier(false);
      props.addSingleTreeToIdentifier(BatchRecordingTypeEnum.Single);
    } else if (enumSelect == BatchRecordingTypeEnum.Multiple) {
      props.addSingleTreeToIdentifier(BatchRecordingTypeEnum.Multiple);
      // props.addDateRecordingIdentifier(false);
    } else {
      props.addSingleTreeToIdentifier(BatchRecordingTypeEnum.Date);
      // props.addDateRecordingIdentifier(true);
    }
  };

  const getFilteredData = (currentState: string, text: string): any => {
    return deliverySheetList.filter(val => val.name.toLowerCase().includes(text.toLowerCase()));
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Batch unique identifier */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="batch_uniqueIdentifier"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={batchForm.batch.uniqueIdentifier ? batchForm.batch.uniqueIdentifier : ''}
            onChangeText={(text: string) => {
              updateBatchForm('uniqueIdentifier', text, true);
            }}
            placeholder={i18n.t('views.batch.batch_registration.insert_batch_unique_identifier_here')}
            textInputTitle={i18n.t('views.batch.batch_registration.unique_identifier')}
            editable={false}
            mandatory={true}
          />

          {/**Validator */}
          {errors['uniqueIdentifier'] && errors['uniqueIdentifier'].length > 0
            ? errors['uniqueIdentifier'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Batch Recording type */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammButtonSelect
            testID={'recording_type_button_select'}
            buttonSelectTitle={i18n.t('generics.choose_recording_type')}
            options={batchRecordingType}
            style={mainGreenButtonSelectActive}
            mandatory={true}
            onSubmit={(data: ButtonSelect) => {
              activeBatchRecordingType(data.enum);
              props.recordingTypeSelected(data.enum);
              setSingleTreeRecord(data.enum);
            }}
          />
        </View>

        {/** Batch name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="batch_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={batchForm.batch.name ? batchForm.batch.name : ''}
            onChangeText={(text: string) => {
              updateBatchForm('name', text, true);
            }}
            placeholder={i18n.t('views.batch.batch_registration.insert_batch_name_here')}
            textInputTitle={i18n.t('views.batch.batch_registration.batch_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Batch creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'batch_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateBatchForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.batch.batch_registration.insert_batch_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        {/** Delivery Sheet list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.batch.batch_registration.choose_delivery_sheet') + getMandatorySymbol(true)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_delivery_sheet'}
            text={i18n.t('views.batch.batch_registration.choose_delivery_sheet')}
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowDeliverySheetSelect(true)}
            disabled={!batchForm.batch.recordingType}
            counter={getCounterBySelected(deliverySheetList)}
          />

          {/**Batch Modal multiple select */}
          {showDeliverySheetSelect ? (
            <UrstammModalSelect
              testID={'modal_batch_multiple_select'}
              visible={showDeliverySheetSelect}
              text={{
                textInputTitle: i18n.t('views.batch.batch_registration.choose_delivery_sheet'),
                textStyle: UrstammStyle.textTitle
                //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
              }}
              closeModal={() => {
                setShowDeliverySheetSelect(false),
                  props.dsProps.resetDSSelected ? props.dsProps.resetDSSelected() : null;
              }}
              confirmButton={{
                enabled: true,
                text: i18n.t('generics.save'),
                submitValue: () => {
                  setShowDeliverySheetSelect(false);
                  props.dsProps.confirmDSSelected ? props.dsProps.confirmDSSelected() : null;
                }
              }}
              listJSX={
                <UrstammList
                  filterMethod={(currentState: string, text: string) => getFilteredData(currentState, text)}
                  elementSelected={(deliverySheetSelected: any) => {
                    props.dsProps.deliverySheetSelected(deliverySheetSelected);
                    saveButtonDisabled(errors, batchForm, deliverySheetList);
                  }}
                  testID={'delivery_sheet_list'}
                  textForEmptyList={i18n.t('views.delivery_sheet.delivery_sheets_not_found')}
                  list={deliverySheetList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  moreItems={props.dsProps.moreItems}
                  filterList={true}
                  multipleSelection={true}
                  filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                  currentState={{
                    //icon: (state) => getIconByLoggingState(state),
                    color: state => getColorByDeliverySheetUrstammState(state),
                    text: state => getTextByDeliverySheetUrstammState(state)
                  }}
                  titleKey={'name'}
                  getTextForData={(data, key) =>
                    key == 'currentUrstammState'
                      ? getTextByDeliverySheetUrstammState(data)
                      : getTextByDsRecordingType(data)
                  }
                  showHash={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="batch_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={batchForm.batch.comment ? batchForm.batch.comment : ''}
            onChangeText={(text: string) => updateBatchForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_batch'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitBatchForm(batchForm.batch)}
            disabled={saveDisabled}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
