import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { PacketImageForm } from '@components/views/packet/PacketDetailsView';
import PacketRegistrationView, { PacketForm } from '@components/views/packet/PacketRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { resetBatchList } from '@redux/features/batch/batchSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import {
  resetCompanyPacketWoodQualityList,
  setCompanyPacketWoodQualityList
} from '@redux/features/packet/companyPacketWoodQualitySlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyPacketWoodQualityResourceApi,
  customCompanySurfaceResourceApi,
  customPacketResourceApi,
  customTrunkResourceApi
} from '@services/apis/ApiConfiguration';
import {
  BatchDTO,
  Company,
  CompanyPacketWoodQuality,
  CompanySurface,
  Packet,
  PacketCertification,
  PacketCertificationCertificationEnum,
  PacketCurrentStateEnum,
  PacketDTO,
  PacketSpecies,
  PacketSpeciesSpeciesEnum,
  Trunk
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  formatPhotoNumber,
  generateSet,
  generateSetForCertification,
  generateSetForPacketSpecies,
  getAllCompanyProcessorConnectedToMe,
  getCompanyProductType,
  handlePacketCreationError,
  handlePacketCreationSuccess,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function PacketRegistrationContainer({ route, navigation }: RootStackScreenProps<'PacketRegistration'>) {
  //Redux
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyProductTypeCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyProductType.completeList
  );

  const rdxCompanyPacketWoodQualityList = useSelector(
    (state: RootState) => state.persistedReducer.companyPacketWoodQuality.list
  );
  const rdxCompanPacketWoodQualityCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyPacketWoodQuality.completeList
  );

  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const rdxCompanySurfaceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companySurface.completeList
  );
  const dispatch = useDispatch();

  const [packetUniqueIdentifier, setPacketUniqueIdentifier] = useState<string>('');
  const [batchSelected, setBatchSelected] = useStateWithCallbackLazy<BatchDTO>({});
  const [trunkListByBatchDS, setTrunkListByBatchDS] = useStateWithCallbackLazy<any[]>([]);
  const [trunkListByBatchDSReference, setTrunkListByBatchDSReference] = useStateWithCallbackLazy<any[]>([]);

  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);

  const [packetSpeciesList, setPacketSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [packetSpeciesListReference, setPacketSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);

  const [processorList, setProcessorList] = useState<Company[]>([]);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.packet'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.batchSelected) {
      setBatchSelected(route.params?.batchSelected, batchUpdated => generatePacketUniqueIdentifier(batchUpdated));
      getAllCompanyProcessorConnectedToMe(dispatch, setProcessorList);
      getCompanyProductType(dispatch);
      getCompanyWoodQualityResourceApi();
      getCompanySurfaceResourceApi();
      getTrunkListByDeliverySheet(route.params?.cloneFromPacket);
      getAllPacketCertifications(route.params?.cloneFromPacket);
      getAllPacketSpecies(route.params?.cloneFromPacket);
    }
  }, [route.params?.batchSelected]);

  const goBack = async (): Promise<void> => {
    dispatch(resetBatchList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToBatchDetails(navigation, batchSelected!);
  };

  const getTrunkListByDeliverySheet = (cloneFromPacket?: Packet | PacketDTO): void => {
    dispatch(changeLoaderStatus(true));
    let trunkByBatchDS: Trunk[] = [];
    let dsList = [...route.params?.batchSelected?.deliverySheets!];

    const trunksSelected = cloneFromPacket?.trunks ? [...cloneFromPacket?.trunks] : [];
    dsList.forEach((ds, idx) => {
      customTrunkResourceApi
        .getTrunksByDeliverySheet({ deliverySheetId: ds.id! })
        .then((list: Trunk[]) => {
          if (list) {
            list.forEach(trunk => trunkByBatchDS.push(trunk));
            if (idx == dsList.length - 1) {
              setTrunkListByBatchDS(trunkByBatchDS, listUpdated => {
                deepCopyAndAddSelectedParameter(
                  listUpdated,
                  setTrunkListByBatchDS,
                  setTrunkListByBatchDSReference,
                  trunksSelected
                );
              });
            }
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    });
  };

  const submitPacket = (data: PacketForm, packetPhoto?: PacketImageForm | null) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<PacketCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<PacketSpecies> = generateSetForPacketSpecies(packetSpeciesList);
    let trunksSet: Set<Trunk> = new Set(generateSet(trunkListByBatchDS));

    let packetObj: Packet = {
      batch: batchSelected as any,
      currentState: PacketCurrentStateEnum.InProgress,
      uniqueIdentifier: data.packet.uniqueIdentifier!,
      name: data.packet.name!,
      creationDate: data.packet.creationDate,
      length: data.packet.length,
      width: data.packet.width,
      discount: data.packet.discount,
      thickness: data.packet.thickness,
      amountOfProducts: data.packet.amountOfProducts,
      dryness: data.packet.dryness,
      planningDimension: data.packet.planningDimension,
      comment: data.packet.comment,
      processor: data.packet.processorCustomName ? undefined : data.packet.processor,
      processorCustomName:
        data.packet.processorCustomName && data.packet.processorCustomName.length > 0
          ? data.packet.processorCustomName
          : undefined,
      packetCertifications: certificationSet,
      packetSpecies: speciesSet,
      productType: data.packet.productType,
      woodQuality: data.packet.woodQuality,
      surface: data.packet.surface,
      trunks: trunksSet,
      longitude: Number(packetPhoto?.longitude),
      latitude: Number(packetPhoto?.latitude),
      photoCreationDate: packetPhoto?.photoCreationDate
    };

    customPacketResourceApi
      .createPacketCustom({ entityWithFileDTOPacket: { entity: packetObj, base64File: packetPhoto?.base64File } })
      .then(async (packet: Packet) => {
        await handlePacketCreationSuccess(dispatch, navigation, batchSelected, packet);
      })
      .catch(async error => {
        await handlePacketCreationError(dispatch, error);
      });
  };

  const getCompanyWoodQualityResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyPacketWoodQualityResourceApi
      .getAllCompanyPacketWoodQualitysForCompany({})
      .then((packetWoodQualityList: CompanyPacketWoodQuality[]) => {
        dispatch(changeLoaderStatus(false));

        if (packetWoodQualityList) {
          dispatch(resetCompanyPacketWoodQualityList());
          dispatch(setCompanyPacketWoodQualityList(packetWoodQualityList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };
  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllPacketCertifications = (cloneFromPacket?: Packet | PacketDTO) => {
    let filterList: any[] = [];
    Object.values(PacketCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == PacketCertificationCertificationEnum.Urstamm,
        disabled: state == PacketCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let packetCertification = cloneFromPacket?.packetCertifications ? [...cloneFromPacket?.packetCertifications] : [];
    if (packetCertification && packetCertification.length > 0) {
      packetCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllPacketSpecies = (cloneFromPacket: Packet | PacketDTO) => {
    let filterList: any[] = Object.values(PacketSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: false,
      active: false,
      disabled: false
    }));

    let packetSpecies = cloneFromPacket?.packetSpecies ? [...cloneFromPacket?.packetSpecies] : [];
    if (packetSpecies && packetSpecies.length > 0) {
      packetSpecies.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.species) {
            val.selected = true;
            val.active = true;
            break;
          }
        }
      });
    }

    setPacketSpeciesList(filterList, () => {});
    setPacketSpeciesListReference(filterList, () => {});
  };

  /**
   * Prefix ex: U_BRU_001
   * U = Urstamm
   * BRU = prefixIdentifier
   * 001 = counter
   */
  const generatePacketUniqueIdentifier = (batchSelected: BatchDTO) => {
    let packets = batchSelected?.packets ? [...batchSelected?.packets] : [];

    if (packets.length > 0) {
      let maxId: string = formatPhotoNumber((Number(packets.length) + 1).toString(), 2);
      return setPacketUniqueIdentifier(batchSelected.uniqueIdentifier + '_' + maxId);
    }
    //Default value
    return setPacketUniqueIdentifier(batchSelected.uniqueIdentifier + '_' + '01');
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'packet_title'}
          text={i18n.t('views.packet.packet_registration.new_packet')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: batchSelected?.name! }]}
      view={
        <PacketRegistrationView
          navigation={navigation}
          batchDTO={batchSelected!}
          uniqueIdentifier={packetUniqueIdentifier}
          submitPacketForm={(packetForm: PacketForm, packetPhoto?: PacketImageForm) =>
            submitPacket(packetForm, packetPhoto ?? null)
          }
          companyProductTypeProps={{
            companyProductTypeList: rdxCompanyProductTypeList
          }}
          companyPacketWoodQualityProps={{
            companyPacketWoodQualityList: rdxCompanyPacketWoodQualityList
          }}
          companySurfaceProps={{
            companySurfaceList: rdxCompanySurfaceList
          }}
          trunkProps={{
            trunkList: trunkListByBatchDS,
            trunkSelected: (data: any) => selectElementFormList(data, setTrunkListByBatchDS, trunkListByBatchDS),
            resetTrunkSelected: () => restoreListToReference(setTrunkListByBatchDS, trunkListByBatchDSReference),
            confirmTrunkSelected: () => applySavingToReference(setTrunkListByBatchDSReference, trunkListByBatchDS)
          }}
          certificationProps={{
            certificationList: certificationList,
            certificationSelected: (data: any) => selectElementFormList(data, setCertificationList, certificationList),
            resetCertificationSelected: () => restoreListToReference(setCertificationList, certificationListReference),
            confirmCertificationSelected: () => applySavingToReference(setCertificationListReference, certificationList)
          }}
          speciesProps={{
            speciesList: packetSpeciesList,
            speciesSelected: (data: any) => selectSlideElementFormList(data, setPacketSpeciesList, packetSpeciesList),
            resetSpeciesSelected: () => restoreListToReference(setPacketSpeciesList, packetSpeciesListReference),
            confirmSpeciesSelected: () => applySavingToReference(setPacketSpeciesListReference, packetSpeciesList)
          }}
          processorList={processorList}
          // speciesProps={(data: any) => getSpeciesListId(data, setSpeciesList, speciesList)}
          cloneFromPacket={route.params?.cloneFromPacket}
        />
      }
    />
  );
}
