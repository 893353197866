import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import TrunkRegistrationView from '@components/views/trunk/TrunkRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { setLoggingList, setTrunkLoggingList } from '@redux/features/logging/loggingSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { resetTreeList, setTreeList } from '@redux/features/tree/treeSlice';
import { RootState } from '@redux/store';
import { FullAvailableDeliverySheetResourceApi } from '@services/apis/FullAvailableDeliverySheetResourceApi';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { FullAvailableTreeResourceApi } from '@services/apis/FullAvailableTreeResourceApi';
import { FullAvailableTrunkResourceApi } from '@services/apis/FullAvailableTrunkResourceApi';
import {
  DeliverySheetDTO,
  DeliverySheetRecordingTypeEnum,
  LoggingDTO,
  TreeDTO,
  TreeDTORecordingTypeEnum,
  Trunk,
  TrunkCurrentStateEnum
} from '@services/apis/generated';
import * as Network from 'expo-network';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetTrunkList } from '../../redux/features/trunk/trunkSlice';
import { clearElementForRedux } from '../../utils/classes/UrstammClassMapper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function TrunkRegistrationContainer({ navigation, route }: RootStackScreenProps<'TrunkRegistration'>) {
  //REDUX

  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxTreeCompleteList = useSelector((state: RootState) => state.persistedReducer.tree.completeList);
  const rdxDSCompleteList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.completeList);
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);
  const rdxLoggingList = useSelector((state: RootState) => state.persistedReducer.logging.list);
  const rdxTreeList = useSelector((state: RootState) => state.persistedReducer.tree.list);
  const rdxBarkDiscountList = useSelector((state: RootState) => state.persistedReducer.calculation.barkDiscountList);
  const dispatch = useDispatch();

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [latestTrunkReference, setLatestTrunkReference] = useState<Trunk>({
    name: '',
    currentState: TrunkCurrentStateEnum.EditedByForestry
  });

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.new_tree_record'),
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  useEffect(() => {
    if (route.params?.deliverySheetSelected.recordingType == DeliverySheetRecordingTypeEnum.Single) {
      getTreeListByDS();
    }
    if (route.params?.latestTrunk) {
      updateTrunkReference(route.params?.latestTrunk);
    }
  }, [route.params]);

  /**
   * If I click on the "go back" button I reset the trunk list
   */
  useEffect(() => {
    return () => {
      dispatch(resetTrunkList());
    };
  }, []);

  const trunksNumber = useSelector((state: RootState) => state.persistedReducer.forest.numberOfTrunks);

  const [isNetworkOk, setIsNetworkOk] = useState<boolean | undefined>(false);

  useEffect(() => {
    // Function to check the network state
    const checkNetworkStatus = async () => {
      try {
        const networkState = await Network.getNetworkStateAsync();
        setIsNetworkOk(networkState.isConnected);
      } catch (error) {
        setIsNetworkOk(false);
      }
    };

    // Check the status when the component mounts
    checkNetworkStatus();

    // Optional: You can set up event listeners to check network changes in real-time
    // const interval = setInterval(checkNetworkStatus, 5000); // Check every 5 seconds

    // return () => clearInterval(interval); // Cleanup the interval when the component unmounts
  }, []);

  const submitTrunkForm = (form: Trunk, navigateToList?: boolean): void => {
    setLoading(true);
    setResetForm(false);
    dispatch(changeLoaderStatus(true));
    form.deliverySheet = route.params?.deliverySheetSelected;
    form.creationDate = new Date();
    //Adding selected logging to form
    FullAvailableTrunkResourceApi.createTrunkCustom(
      rdxOfflineSyncList,
      rdxBarkDiscountList,
      dispatch,
      addOfflineObjectSync,
      { trunk: form }
    )
      .then(res => {
        dispatch(changeLoaderStatus(false));
        if (res) {
          setLoading(false);
          setResetForm(true);
          updateTrunkReference(form);

          if (navigateToList) {
            dispatch(resetTrunkList());
            setResetForm(true);

            //Updating the delivery sheet selected
            FullAvailableDeliverySheetResourceApi.getDeliverySheetCustom(rdxDSCompleteList, {
              id: route.params?.deliverySheetSelected.id
            })
              .then((ds: DeliverySheetDTO) => {
                if (ds) {
                  // const updatedDs = route.params?.deliverySheetSelected;
                  // updatedDs.trunks = [...(updatedDs.trunks || []), res];
                  // console.log('updated ds -> ', updatedDs);
                  UrstammNavigationHelper.navigateToDeliverySheetDetails(navigation, ds, true);
                }
              })
              .catch(async error => {
                dispatch(changeLoaderStatus(false));
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });

            FullAvailableLoggingResourceApi.getLoggingCustom(rdxLoggingCompleteList, {
              id: route.params?.deliverySheetSelected?.logging?.id
            })
              .then((logging: LoggingDTO) => {
                if (logging) {                
                  const updatedLogging = {
                    ...logging,
                    numberOfTrunks: Number(logging.numberOfTrunks ?? 0) + 1,
                  };                
                  dispatch(setLoggingList([updatedLogging]));
                }
              })
              .catch(async error => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });
          }
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        setLoading(false);
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getTreeListByDS = () => {
    dispatch(changeLoaderStatus(true));
    let loggingId = route.params?.deliverySheetSelected.logging.id;

    FullAvailableTreeResourceApi.getTreesByLogging(rdxTreeCompleteList, {
      loggingId: loggingId
    })
      .then((list: TreeDTO[]) => {
        dispatch(changeLoaderStatus(false));

        if (list) {
          let singleTreeList = list.filter(val => val.recordingType != TreeDTORecordingTypeEnum.Multiple);
          dispatch(resetTreeList());
          dispatch(setTreeList(singleTreeList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const updateTrunkReference = (trunk: Trunk) => {
    if (trunk) {
      setLatestTrunkReference(prevState => ({
        ...prevState,
        name: generateTrunkNumberFas(trunk).toString(),
        trunkNumberFAS: generateTrunkNumberFas(trunk),
        treePhotoNumber: trunk.treePhotoNumber,
        length: trunk.length,
        diameter: trunk.diameter,
        species: trunk.species,
        quality: trunk.quality
      }));
    }
  };

  const generateTrunkNumberFas = (latestTrunk: Trunk): number => {
    if (latestTrunk && latestTrunk?.trunkNumberFAS) {
      let numberFas = latestTrunk.trunkNumberFAS;
      return ++numberFas;
    }
    return 0;
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'delivery_sheet_title'}
          text={i18n.t('views.trunk.trunk_registration.new_trunk')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={
        route.params?.deliverySheetSelected
          ? [
              {
                title: i18n.t('generics.delivery_sheet'),
                value: route.params?.deliverySheetSelected.name!
              }
            ]
          : undefined
      }
      view={
        <TrunkRegistrationView
          navigation={navigation}
          submitTrunkForm={(form: Trunk) => submitTrunkForm(form, true)}
          saveAndContinue={(form: Trunk) => submitTrunkForm(form)}
          deliverySheetSelected={route.params?.deliverySheetSelected}
          treeList={rdxTreeList}
          resetForm={resetForm}
          trunkReference={latestTrunkReference}
          loading={loading}
        />
      }
    />
  );
}
