import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import { i18n } from '@i18n/i18n';
import {
  Logging,
  OtherAssortment,
  OtherAssortmentOtherAssortmentTypeEnum,
  OtherAssortmentSpeciesEnum,
  Pile,
  PileWoodTypeEnum
} from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByPileState,
  getIconByPileState,
  getTextByOtherAssortmentSpecies,
  getTextByPileState,
  loggingStateIsClosed
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilUpdatePhoto,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { getTreeDTOSpeciesList, urstammUtilOpenMaps } from '../../../utils/classes/UrstammUtilityFunctions';
import { buildPilePhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton, mainGreenButtonSelectActive } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';
import { PileRegistration } from './PileRegistrationView';

export interface PileForm {
  pile: PileRegistration;
  base64File?: string;
}

export default function PileDetailsView(props: {
  navigation: any;
  pileSelected: Pile;
  otherAssortment?: OtherAssortment;
  modalVisible: boolean;
  loggingSelected: Logging;
  updatePile: (pileForm: PileForm) => void;
}) {
  const [pileSelected, setPileSelected] = useState<Pile>({});
  const [otherAssortmentActive, setOtherAssortmentActive] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [treeSpeciesList, setTreeSpeciesList] = useState<SlideButtonOption[]>([]);
  const [photoPreview, setPhotoPreview] = useState<string | null>('');

  const [pileForm, setPileForm] = useStateWithCallbackLazy<PileForm>({
    pile: {},
    base64File: undefined
  });

  const [woodType, setWoodType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('generics.other_assortment'),
      enum: PileWoodTypeEnum.OtherAssortment,
      active: false
    },
    {
      text: i18n.t('generics.timber'),
      enum: PileWoodTypeEnum.Timber,
      active: false
    }
  ]);

  const [otherAssortmentType, setOtherAssortmentType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('generics.energy'),
      enum: OtherAssortmentOtherAssortmentTypeEnum.EnergyWood,
      active: false
    },
    {
      text: i18n.t('generics.industrial_wood'),
      enum: OtherAssortmentOtherAssortmentTypeEnum.IndustrialWood,
      active: false
    },
    {
      text: i18n.t('generics.fire_wood'),
      enum: OtherAssortmentOtherAssortmentTypeEnum.FireWood,
      active: false
    }
  ]);

  useEffect(() => {
    getSlideButtonOptionsTreeSpecies();
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if (props.pileSelected && props.pileSelected.id) {
      setPileSelected(props.pileSelected);

      setPileForm(
        {
          pile: {
            id: props.pileSelected.id,
            name: props.pileSelected.name,
            estimatedVolume: props.pileSelected.estimatedVolume,
            woodType: props.pileSelected.woodType,
            species:
              props.pileSelected.woodType == PileWoodTypeEnum.OtherAssortment
                ? props.otherAssortment?.species
                : undefined,
            otherAssortmentType:
              props.pileSelected.woodType == PileWoodTypeEnum.OtherAssortment
                ? props.otherAssortment?.otherAssortmentType
                : undefined,
            comment: props.pileSelected.comment
          },
        },
        updatedPileForm => {
          saveButtonDisabled(errors, updatedPileForm);
        }
      );

      if (props.otherAssortment) {
        activeOtherAssortmentType(props.otherAssortment?.otherAssortmentType!);
        if (props.otherAssortment.species)
          setPileTreeSpecie({
            id: props.otherAssortment.species,
            text: getTextByOtherAssortmentSpecies(props.otherAssortment.species!),
            active: false
          });
      }

      if (props.pileSelected?.photoFilepath) {
        const photoFilepath = props.pileSelected.photoFilepath;
        if (photoFilepath.startsWith('piles')) {
          buildPilePhotoUrl(props.pileSelected).then(res => {
            setPhotoPreview(res);
          });
        } else {
          setPhotoPreview(`data:image/png;base64,${photoFilepath}`);
        }
      }
      // else {
      //   readPilePhoto(props.pileSelected.id).then(photo => {
      //     setPhotoPreview('data:image/png;base64,' + photo);
      //   });
      // }
      if (props.pileSelected.woodType) {
        activeWoodType(props.pileSelected.woodType!);
      }
    }
  }, [props.pileSelected, props.otherAssortment]);

  const activeWoodType = (woodTypeEnum: PileWoodTypeEnum): void => {
    woodType.forEach(type => {
      type.active = type.enum == woodTypeEnum ? true : false;
    });
    setWoodType([...woodType]);
    getActiveWoodType();
    if (woodTypeEnum == PileWoodTypeEnum.OtherAssortment) {
      setValidators(prev => ({
        ...prev,
        estimatedVolume: { required: true, numeric: true }
      }));
    } else {
      setValidators(prev => ({ ...prev, estimatedVolume: {} }));
    }
  };

  const activeOtherAssortmentType = (assortmentType: OtherAssortmentOtherAssortmentTypeEnum): void => {
    otherAssortmentType.forEach(type => {
      type.active = type.enum == assortmentType ? true : false;
    });
    setOtherAssortmentType([...otherAssortmentType]);
  };

  /**
   * FORM UPDATE
   */
  const updatePileForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, pileForm, 'pile');
    setPileForm(
      { pile: form['pile'], base64File: form['base64File'] },
      mustValidate ? updatedPileForm => validate(value, key, updatedPileForm) : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    const photoCreationDate = new Date();
    let form = urstammUtilUpdatePhoto(base64, location, photoCreationDate, pileForm, 'pile');
    setPileForm(
      { pile: form['pile'], base64File: form['base64File'] },
      mustValidate ? updatedPileForm => validate(base64, 'base64File', updatedPileForm) : () => {}
    );
  };

  const getActiveWoodType = (): void => {
    woodType.forEach(type => {
      if (type.enum == PileWoodTypeEnum.OtherAssortment) {
        if (type.active) {
          setOtherAssortmentActive(true);
        } else {
          setOtherAssortmentActive(false);
        }
      }
    });
  };

  const setPileTreeSpecie = (selected: SlideButtonOption): void => {
    treeSpeciesList.forEach(type => {
      type.active = type.id == selected.id ? true : false;
    });
    setTreeSpeciesList([...treeSpeciesList]);
  };

  const getSlideButtonOptionsTreeSpecies = (): void => {
    let filterList: SlideButtonOption[] = [];
    (getTreeDTOSpeciesList() as OtherAssortmentSpeciesEnum[]).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByOtherAssortmentSpecies(state),
        active: false
      };
      filterList.push(type);
    });
    setTreeSpeciesList(filterList);
  };

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const [validators, setValidators] = useState<any>({
    name: { required: true },
    woodType: { required: true },
    estimatedVolume: {},
    species: { required: true },
    otherAssortmentType: { required: true }
  });

  useEffect(() => {
    let isTimberOrEstimatedIsSet =
      pileForm.pile.woodType == PileWoodTypeEnum.Timber ||
      (pileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment && pileForm.pile.estimatedVolume);
    if (isTimberOrEstimatedIsSet) validate(pileForm.pile.estimatedVolume as any, 'estimatedVolume', pileForm);
  }, [validators]);

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    woodType: [],
    estimatedVolume: [],
    species: [],
    otherAssortmentType: []
  });

  const validate = (value: string, key: string, updatedPileForm: PileForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedPileForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPileForm: PileForm) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      updatedPileForm.pile.name?.length == 0 ||
      !updatedPileForm.pile.woodType ||
      (updatedPileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment && !updatedPileForm.pile.estimatedVolume) ||
      (updatedPileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment &&
        (!updatedPileForm.pile.species || !updatedPileForm.pile.otherAssortmentType))
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column' }]}>
        {/**Status and creation date */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <View style={UrstammStyleContainer.row1SpaceBetween}>
            {pileSelected?.currentState && (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                {pileSelected.currentState && getIconByPileState(pileSelected.currentState)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByPileState(pileSelected.currentState)
                  ]}>
                  {getTextByPileState(pileSelected.currentState)}
                </Text>
              </View>
            )}
            {pileSelected?.creationDate ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.wrap]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(pileSelected.creationDate).format('DD/MM/yyyy')}
                  {/* {moment(pileSelected.creationDate).format('DD/MM/yyyy HH:mm')} */}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {/**Localization */}
        {pileSelected?.latitude && pileSelected?.longitude ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexStartX, UrstammStyle.flexCenterY]}>
              <TouchableOpacity onPress={() => urstammUtilOpenMaps(pileSelected)}>
                <UrstammIcon name={'map-marked-alt'} size={20} color={ColorTheme.mainGreen} type={'FontAwesome5'} />
              </TouchableOpacity>

              <View style={[UrstammStyle.flexColumn, { paddingLeft: 16 }]}>
                {/**Latitude */}
                <View style={[UrstammStyle.flexRow]}>
                  <Text style={[UrstammStyle.titleStyle]}>{i18n.t('generics.latitude') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{pileSelected.latitude}</Text>
                </View>

                {/**Longitude */}
                <View style={[UrstammStyle.flexRow]}>
                  <Text style={[UrstammStyle.titleStyle]}>{i18n.t('generics.longitude') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{pileSelected.longitude}</Text>
                </View>
              </View>
            </View>
          </View>
        ) : null}

        <View style={UrstammStyle.divider} />

        {/** Name */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammInput
            testID="pile_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: false,
              iconName: 'user',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) => {
              updatePileForm('name', text, true);
            }}
            value={pileForm.pile.name ? pileForm.pile.name : ''}
            editable={!loggingStateIsClosed(props.loggingSelected.currentState)}
            placeholder={i18n.t('views.pile.pile_registration.insert_pile_name_here')}
            textInputTitle={i18n.t('generics.name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Wood type */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonSelect
            testID={'wood_type_button_select'}
            buttonSelectTitle={i18n.t('views.pile.choose_type_of_pile')}
            options={woodType}
            disabled={
              props.pileSelected.woodType! || loggingStateIsClosed(props.loggingSelected.currentState) ? true : false
            }
            style={mainGreenButtonSelectActive}
            onSubmit={(data: ButtonSelect) => {
              activeWoodType(data.enum);
              updatePileForm('woodType', data.enum, true);
            }}
            mandatory={true}
          />

          {/**Validator */}
          {errors['woodType'] && errors['woodType'].length > 0
            ? errors['woodType'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Type of other assortment */}
        {otherAssortmentActive && (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammButtonSelect
              testID={'wood_type_button_select'}
              options={otherAssortmentType}
              buttonSelectTitle={i18n.t('generics.type_of_other_assortment')}
              style={mainGreenButtonSelectActive}
              disabled={
                props.pileSelected.woodType! || loggingStateIsClosed(props.loggingSelected.currentState) ? true : false
              }
              onSubmit={(data: ButtonSelect) => {
                activeOtherAssortmentType(data.enum), updatePileForm('otherAssortmentType', data.enum, true);
              }}
              mandatory={true}
            />

            {/**Validator */}
            {errors['otherAssortmentType'] && errors['otherAssortmentType'].length > 0
              ? errors['otherAssortmentType'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        )}

        {/**Type of other assortment */}
        {otherAssortmentActive && (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammSlideButton
              testID={'button_tree_species'}
              slideButtonList={treeSpeciesList}
              slideButtonTitle={i18n.t('generics.tree_species')}
              slideButtonRotatedTitle={i18n.t('generics.species')}
              mandatory={true}
              disabled={loggingStateIsClosed(props.loggingSelected.currentState) ? true : false}
              buttonPressed={(selected: SlideButtonOption) => {
                setPileTreeSpecie(selected), updatePileForm('species', selected.id, true);
              }}
            />
            {/**Validator */}
            {errors['species'] && errors['species'].length > 0
              ? errors['species'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        )}

        {/**Pile image */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammFilePicker
            locationProps={{ latitude: pileSelected.latitude, longitude: pileSelected.longitude }}
            testID={'pile_image'}
            inputStyle={UrstammStyleContainer.cameraContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            icon={{
              showIcon: true,
              iconName: 'camera',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.pile.upload_pile_file')}
            textInputTitle={i18n.t('views.pile.upload_pile_file')}
            uri={photoPreview}
            disabled={
              loggingStateIsClosed(props.loggingSelected.currentState) 
              // || props.pileSelected.photoFilepath != undefined
            }
            uploadType={UploadType.camera}
            mandatory={false}
            mediaTypeUploaded={PickerMediaType.Image}
            deleteBase64={() => updatePileForm('base64', undefined)}
            saveBase64={(base64: string, location: any) => updatePhoto(base64, location, false)}
          />
        </View>

        {/** Estimated volume */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="estimated_volume_input"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={pileForm.pile.estimatedVolume ? pileForm.pile.estimatedVolume.toString() : ''}
            keyboardType={'numeric'}
            placeholder={i18n.t('generics.insert_estimated_volume_here')}
            textInputTitle={i18n.t('generics.estimated_volume')}
            onChangeText={(text: string) => updatePileForm('estimatedVolume', text, true)}
            editable={!loggingStateIsClosed(props.loggingSelected.currentState)}
            mandatory={pileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment}
          />
          {/**Validator */}
          {pileForm.pile.woodType == PileWoodTypeEnum.OtherAssortment &&
          errors['estimatedVolume'] &&
          errors['estimatedVolume'].length > 0
            ? errors['estimatedVolume'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="pile_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => updatePileForm('comment', text, false)}
            value={pileForm.pile.comment ? pileForm.pile.comment : ''}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            disabled={loggingStateIsClosed(props.loggingSelected.currentState) || saveDisabled}
            onSubmit={() => props.updatePile(pileForm)}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
