import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect, { selectElementFormList } from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { customProcessorBatchResourceApi } from '@services/apis/ApiConfiguration';
import { ProcessorBatchDTO, ProcessorBatchRecordingTypeEnum, UserExtended } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { getColorByProductState, getTextByProductState } from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessorOrProcessorShippingNoteId } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator
} from '../../../utils/classes/UrstammUtilityForm';
import {
  checkIfAtLeastOneProcessorShippingNoteIsSelected,
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  getMandatorySymbol,
  isCompanyMock
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
  mainGreenButtonSelectActive
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

//Future developments
export interface ProcessorBatchForm {
  processorBatch: ProcessorBatchDTO;
}

interface ProcessorShippingNoteProps {
  processorShippingNoteList: any[];
  processorShippingNoteSelected: (data: any) => void;
  resetProcessorShippingNoteSelected?: () => void;
  confirmProcessorShippingNoteSelected?: () => void;
  moreItems: () => void;
}

export default function ProcessorBatchRegistrationView(props: {
  navigation: any;
  uniqueIdentifier: string;
  submitProcessorBatchForm: (data: ProcessorBatchDTO, shippingNote: any) => void;
  resetProcessorBatchForm: boolean;
  recordingTypeSelected: (data: ProcessorBatchRecordingTypeEnum) => void;
  userExtendedMe?: UserExtended;
  dsProps: ProcessorShippingNoteProps;
  addSingleTreeToIdentifier: (add: string) => void;
}) {
  const [processorBatchForm, setProcessorBatchForm] = useStateWithCallbackLazy<ProcessorBatchForm>({
    processorBatch: {
      uniqueIdentifier: props.uniqueIdentifier ? props.uniqueIdentifier : '',
      name: '',
      creationDate: new Date(),
      recordingType: undefined,
      comment: ''
    }
  });

  const [processorShippingNoteList, setProcessorShippingNoteList] = useStateWithCallbackLazy<any>(() => {});
  const [uniqueIdentifier, setUniqueIdentifier] = useState<string>(props.uniqueIdentifier);
  const [showProcessorShippingNoteSelect, setShowProcessorShippingNoteSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [recordingType, setRecordingType] = useState<any>();

  const [processorBatchRecordingType, setProcessorBatchRecordingType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('generics.multiple'),
      enum: ProcessorBatchRecordingTypeEnum.Multiple,
      active: false
    },
    {
      text: i18n.t('generics.date'),
      enum: ProcessorBatchRecordingTypeEnum.Date,
      active: false
    },
    {
      text: i18n.t('generics.single'),
      enum: ProcessorBatchRecordingTypeEnum.Single,
      active: false
    }
  ]);

  let keys: ListSubData[] = [
    // { title: undefined, titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'currentState', keyId: '1currentState', dataContainerViewStyle: { height: 24, flexBasis: '50%' }, isEnum: true },
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.processor_shipping_note_id') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processorShippingNoteId',
      keyId: '1processorShippingNoteId',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
      // isEnum: true
    },
    {
      title: i18n.t('generics.sender') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1creator',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      isEnum: true
    }
  ];

  useEffect(() => {
    if (props.uniqueIdentifier) {
      updateProcessorBatchForm('uniqueIdentifier', props.uniqueIdentifier);
      setUniqueIdentifier(props.uniqueIdentifier);
    }
    if (props.resetProcessorBatchForm) {
      clearForm();
      resetTreeRecordingType();
    }
  }, [props.uniqueIdentifier, props.resetProcessorBatchForm]);

  // useEffect(() => {
  //   if (props.dsProps.processorShippingNoteList)
  //     setProcessorShippingNoteList(props.dsProps.processorShippingNoteList, updatedProcessorShippingNoteList => {
  //       saveButtonDisabled(errors, processorBatchForm, updatedProcessorShippingNoteList);
  //     });
  // }, [props.dsProps.processorShippingNoteList]);

  const activeProcessorBatchRecordingType = (
    processorBatchRecordingTypeEnum: ProcessorBatchRecordingTypeEnum
  ): void => {
    updateProcessorBatchForm('recordingType', processorBatchRecordingTypeEnum);

    processorBatchRecordingType.forEach(type => {
      type.active = type.enum == processorBatchRecordingTypeEnum ? true : false;
    });
    setProcessorBatchRecordingType([...processorBatchRecordingType]);
  };

  /**
   * Resetting processorBatch form for new recording
   */
  const clearForm = () => {
    setProcessorBatchForm(
      prevState => ({
        ...prevState,
        processorBatch: {
          uniqueIdentifier: props.uniqueIdentifier ? props.uniqueIdentifier : '',
          name: '',
          recordingType: undefined,
          comment: ''
        }
      }),
      updatedProcessorBatchForm => {
        saveButtonDisabled(errors, updatedProcessorBatchForm);
      }
    );
  };

  /**
   * Restoring initial value
   */
  const resetTreeRecordingType = (): void => {
    processorBatchRecordingType.forEach(type => {
      type.active = false;
    });
    setProcessorBatchRecordingType([...processorBatchRecordingType]);
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateProcessorBatchForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, processorBatchForm, 'processorBatch');
    setProcessorBatchForm(
      { processorBatch: form['processorBatch'] },
      mustValidate ? updatedProcessorBatchForm => validate(value, key, updatedProcessorBatchForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true },
    recordingType: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    recordingType: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ProcessorBatchForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm, processorShippingNoteList)
    );
  };

  useEffect(() => {
    saveButtonDisabled(errors, processorBatchForm, processorShippingNoteList);
  }, [processorShippingNoteList]);

  const saveButtonDisabled = (
    errorsUpdated,
    updatedProcessorBatchForm?: ProcessorBatchForm,
    processorShippingNoteList?: any
  ) => {
    let disabled: boolean = false;
    if (
      !updatedProcessorBatchForm?.processorBatch.name ||
      !updatedProcessorBatchForm?.processorBatch.recordingType ||
      !checkIfAtLeastOneProcessorShippingNoteIsSelected(processorShippingNoteList)
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const setSingleTreeRecord = (enumSelect: ProcessorBatchRecordingTypeEnum) => {
    if (enumSelect == ProcessorBatchRecordingTypeEnum.Single) {
      // props.addDateRecordingIdentifier(false);
      props.addSingleTreeToIdentifier(ProcessorBatchRecordingTypeEnum.Single);
    } else if (enumSelect == ProcessorBatchRecordingTypeEnum.Multiple) {
      props.addSingleTreeToIdentifier(ProcessorBatchRecordingTypeEnum.Multiple);
      // props.addDateRecordingIdentifier(false);
    } else {
      props.addSingleTreeToIdentifier(ProcessorBatchRecordingTypeEnum.Date);
      // props.addDateRecordingIdentifier(true);
    }
    // if(enumSelect == ProcessorBatchRecordingTypeEnum.Single) {
    //   props.addSingleTreeToIdentifier(true);
    // } else if(enumSelect == ProcessorBatchRecordingTypeEnum.Multiple) {
    //   props.addSingleTreeToIdentifier(false);
    // }
  };

  const getProcessorNameByProcessorShippingNote = (data: any) => {
    let value: string | null = null;
    value = '--';
    if (data?.creator && !isCompanyMock(data?.creator)) {
      value = data.creator.name;
    } else if (data?.mockProcessor) {
      value = data.mockProcessor.name;
    } else if (data?.creator && isCompanyMock(data?.creator)) {
      value = i18n.t('views.shipping_note.no_urstamm_processor');
    }
    return value;
  };

  useEffect(() => {
    if (recordingType) {
      customProcessorBatchResourceApi
        .getProductByRecordingTypeCustom({ recordingType: recordingType })
        .then((value: any) => {
          setProcessorShippingNoteList(Array.from(value), () => {});

          // dispatch(changeLoaderStatus(false));
          // if (processorBatch) {
          //   setResetForm(true);
          //   dispatch(resetProcessorBatchList());
          //   UrstammNavigationHelper.navigateToProcessorBatchList(navigation, true);
          // }
        })
        .catch(async error => {
          console.log('error -> ', error);
          // dispatch(changeLoaderStatus(false));
          // let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
          // let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          // AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  }, [recordingType]);

  const getFilteredShipmentData = (currentState: string, text: string): any => {
    return processorShippingNoteList.filter(val => val.name.toLowerCase().includes(text.toLowerCase()));
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** ProcessorBatch unique identifier */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="processorBatch_uniqueIdentifier"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={
              processorBatchForm.processorBatch.uniqueIdentifier
                ? processorBatchForm.processorBatch.uniqueIdentifier
                : ''
            }
            onChangeText={(text: string) => {
              updateProcessorBatchForm('uniqueIdentifier', text, true);
            }}
            placeholder={i18n.t(
              'views.processor_batch.processor_batch_registration.insert_processor_batch_unique_identifier_here'
            )}
            textInputTitle={i18n.t('views.processor_batch.processor_batch_registration.unique_identifier')}
            editable={false}
            mandatory={true}
          />

          {/**Validator */}
          {errors['uniqueIdentifier'] && errors['uniqueIdentifier'].length > 0
            ? errors['uniqueIdentifier'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** ProcessorBatch Recording type */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammButtonSelect
            testID={'recording_type_button_select'}
            buttonSelectTitle={i18n.t('generics.choose_recording_type')}
            options={processorBatchRecordingType}
            style={mainGreenButtonSelectActive}
            mandatory={true}
            onSubmit={(data: ButtonSelect) => {
              activeProcessorBatchRecordingType(data.enum);
              props.recordingTypeSelected(data.enum);
              setSingleTreeRecord(data.enum);
              setRecordingType(data.enum);
            }}
          />
        </View>

        {/** ProcessorBatch name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="processorBatch_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={processorBatchForm.processorBatch.name ? processorBatchForm.processorBatch.name : ''}
            onChangeText={(text: string) => {
              updateProcessorBatchForm('name', text, true);
            }}
            placeholder={i18n.t('views.processor_batch.processor_batch_registration.insert_processor_batch_name_here')}
            textInputTitle={i18n.t('views.processor_batch.processor_batch_registration.processor_batch_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** ProcessorBatch creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'processorBatch_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateProcessorBatchForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.processor_batch.processor_batch_registration.insert_processor_batch_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        {/** Delivery Sheet list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.processor_batch.processor_batch_registration.choose_products') + getMandatorySymbol(true)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_products'}
            text={i18n.t('views.processor_batch.processor_batch_registration.choose_products')}
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowProcessorShippingNoteSelect(true)}
            disabled={!processorBatchForm.processorBatch.recordingType}
            counter={getCounterBySelected(processorShippingNoteList)}
          />

          {/**ProcessorBatch Modal multiple select */}
          {showProcessorShippingNoteSelect ? (
            <UrstammModalSelect
              testID={'modal_processorBatch_multiple_select'}
              visible={showProcessorShippingNoteSelect}
              text={{
                textInputTitle: i18n.t('views.processor_batch.processor_batch_registration.choose_products'),
                textStyle: UrstammStyle.textTitle
                //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.processor_shipping_note_selected')+ ': ' + getCounterByProcessorShippingNoteSelected()
              }}
              closeModal={() => {
                setShowProcessorShippingNoteSelect(false),
                  props.dsProps.resetProcessorShippingNoteSelected
                    ? props.dsProps.resetProcessorShippingNoteSelected()
                    : null;
              }}
              confirmButton={{
                enabled: true,
                text: i18n.t('generics.save'),
                submitValue: () => {
                  setShowProcessorShippingNoteSelect(false);
                  props.dsProps.confirmProcessorShippingNoteSelected
                    ? props.dsProps.confirmProcessorShippingNoteSelected()
                    : null;
                }
              }}
              listJSX={
                <UrstammList
                  filterMethod={(currentState: string, text: string) => getFilteredShipmentData(currentState, text)}
                  elementSelected={(processorShippingNoteSelected: any) => {
                    selectElementFormList(
                      processorShippingNoteSelected,
                      setProcessorShippingNoteList,
                      processorShippingNoteList
                    );
                    saveButtonDisabled(errors, processorBatchForm, processorShippingNoteList);
                  }}
                  showHash={false}
                  testID={'source_product_list'}
                  textForEmptyList={i18n.t('views.processor_batch.products_not_found')}
                  titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                  list={processorShippingNoteList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  moreItems={props.dsProps.moreItems}
                  filterList={true}
                  multipleSelection={true}
                  filterListFunction={(text, data) =>
                    filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessorOrProcessorShippingNoteId(
                      text,
                      data,
                      props.userExtendedMe!
                    )
                  }
                  currentState={{
                    //icon: (state) => getIconByLoggingState(state),
                    color: state => getColorByProductState(state),
                    text: state => getTextByProductState(state)
                  }}
                  getTextForFullData={(data, key) => {
                    if (key === '_acceptedProcessorShippingNoteId' && data)
                      return data._acceptedProcessorShippingNote?.id;
                    else if (key === '_acceptedProcessorShippingNoteCreator' && data)
                      return getProcessorNameByProcessorShippingNote(data._acceptedProcessorShippingNote);
                    return null;
                  }}
                />
              }
            />
          ) : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="processorBatch_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={processorBatchForm.processorBatch.comment ? processorBatchForm.processorBatch.comment : ''}
            onChangeText={(text: string) => updateProcessorBatchForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_processorBatch'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() =>
              props.submitProcessorBatchForm(processorBatchForm.processorBatch, processorShippingNoteList)
            }
            disabled={saveDisabled}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
