/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverySheet } from './DeliverySheet';
import {
    DeliverySheetFromJSON,
    DeliverySheetFromJSONTyped,
    DeliverySheetToJSON,
} from './DeliverySheet';
import type { Logging } from './Logging';
import {
    LoggingFromJSON,
    LoggingFromJSONTyped,
    LoggingToJSON,
} from './Logging';
import type { OtherAssortment } from './OtherAssortment';
import {
    OtherAssortmentFromJSON,
    OtherAssortmentFromJSONTyped,
    OtherAssortmentToJSON,
} from './OtherAssortment';
import type { PileState } from './PileState';
import {
    PileStateFromJSON,
    PileStateFromJSONTyped,
    PileStateToJSON,
} from './PileState';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Pile
 */
export interface Pile {
    /**
     * 
     * @type {number}
     * @memberof Pile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Pile
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    woodType?: PileWoodTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Pile
     */
    estimatedVolume?: number;
    /**
     * 
     * @type {number}
     * @memberof Pile
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    currentState?: PileCurrentStateEnum;
    /**
     * 
     * @type {number}
     * @memberof Pile
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Pile
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    photoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    photoFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    photoMd5?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Pile
     */
    automaticallyCreated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Pile
     */
    uuid?: string;
    /**
     * 
     * @type {Logging}
     * @memberof Pile
     */
    logging?: Logging;
    /**
     * 
     * @type {User}
     * @memberof Pile
     */
    photoAuthor?: User;
    /**
     * 
     * @type {Set<DeliverySheet>}
     * @memberof Pile
     */
    deliverySheets?: Set<DeliverySheet>;
    /**
     * 
     * @type {Set<OtherAssortment>}
     * @memberof Pile
     */
    otherAssortments?: Set<OtherAssortment>;
    /**
     * 
     * @type {Set<PileState>}
     * @memberof Pile
     */
    pileStates?: Set<PileState>;
    /**
     * 
     * @type {Date}
     * @memberof Pile
     */
    photoCreationDate?: Date;
    deliverySheetCreated?: boolean;
}


/**
 * @export
 */
export const PileWoodTypeEnum = {
    Timber: 'TIMBER',
    OtherAssortment: 'OTHER_ASSORTMENT'
} as const;
export type PileWoodTypeEnum = typeof PileWoodTypeEnum[keyof typeof PileWoodTypeEnum];

/**
 * @export
 */
export const PileCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type PileCurrentStateEnum = typeof PileCurrentStateEnum[keyof typeof PileCurrentStateEnum];


/**
 * Check if a given object implements the Pile interface.
 */
export function instanceOfPile(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PileFromJSON(json: any): Pile {
    return PileFromJSONTyped(json, false);
}

export function PileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'woodType': !exists(json, 'woodType') ? undefined : json['woodType'],
        'estimatedVolume': !exists(json, 'estimatedVolume') ? undefined : json['estimatedVolume'],
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'photoFilename': !exists(json, 'photoFilename') ? undefined : json['photoFilename'],
        'photoFilepath': !exists(json, 'photoFilepath') ? undefined : json['photoFilepath'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'photoMd5': !exists(json, 'photoMd5') ? undefined : json['photoMd5'],
        'automaticallyCreated': !exists(json, 'automaticallyCreated') ? undefined : json['automaticallyCreated'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'logging': !exists(json, 'logging') ? undefined : LoggingFromJSON(json['logging']),
        'photoAuthor': !exists(json, 'photoAuthor') ? undefined : UserFromJSON(json['photoAuthor']),
        'deliverySheets': !exists(json, 'deliverySheets') ? undefined : (new Set((json['deliverySheets'] as Array<any>).map(DeliverySheetFromJSON))),
        'otherAssortments': !exists(json, 'otherAssortments') ? undefined : (new Set((json['otherAssortments'] as Array<any>).map(OtherAssortmentFromJSON))),
        'pileStates': !exists(json, 'pileStates') ? undefined : (new Set((json['pileStates'] as Array<any>).map(PileStateFromJSON))),
        'deliverySheetCreated': !exists(json, 'deliverySheetCreated') ? false : json['deliverySheetCreated'],
    };
}

export function PileToJSON(value?: Pile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'woodType': value.woodType,
        'estimatedVolume': value.estimatedVolume,
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'photoFilename': value.photoFilename,
        'photoFilepath': value.photoFilepath,
        'photoUrl': value.photoUrl,
        'photoMd5': value.photoMd5,
        'automaticallyCreated': value.automaticallyCreated,
        'uuid': value.uuid,
        'logging': LoggingToJSON(value.logging),
        'photoAuthor': UserToJSON(value.photoAuthor),
        'deliverySheets': value.deliverySheets === undefined ? undefined : (Array.from(value.deliverySheets as Set<any>).map(DeliverySheetToJSON)),
        'otherAssortments': value.otherAssortments === undefined ? undefined : (Array.from(value.otherAssortments as Set<any>).map(OtherAssortmentToJSON)),
        'pileStates': value.pileStates === undefined ? undefined : (Array.from(value.pileStates as Set<any>).map(PileStateToJSON)),
        'photoCreationDate': value.photoCreationDate === undefined ? undefined : value.photoCreationDate,
    };
}

