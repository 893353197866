import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import { Project, ProjectCurrentStateEnum } from '@services/apis/generated/models/Project';
import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { getColorByProductState, getTextByProductState } from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  getMandatorySymbol
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

export interface ProjectForm {
  project: Project;
  projectPhoto?: string;
}

export interface ProjectImageForm {
  deletePrevious?: boolean;
  base64File?: string;
  longitude?: string;
  latitude?: string;
  photoCreationDate?: Date;
}

export interface ProductProps {
  list: any[];
  selected: (data: any) => void;
  resetSelected?: () => void;
  confirmSelected?: () => void;
  moreItems: () => void;
}

export interface ProjectProps {
  incomingProjectList: any[];
  incomingProjectSelected: (data: any) => void;
  resetProjectSelected: () => void;
  confirmProjectSelected: () => void;
  getMoreProject?: () => void;
}

export default function ProjectRegistrationView(props: {
  navigation: any;
  uniqueNumber: string;
  submitProjectForm: (data: ProjectForm, imageData: ProjectImageForm) => void;
  productsPros: ProductProps;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const [uniqueNumber, setUniqueNumber] = useState<string>(props.uniqueNumber);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [sourceProductList, setSourceProductList] = useStateWithCallbackLazy<any>([]);
  const [showSourceProductSelect, setShowSourceProductSelect] = useState<boolean>(false);

  const [projectForm, setProjectForm] = useStateWithCallbackLazy<ProjectForm>({
    project: {
      uniqueNumber: props.uniqueNumber,
      name: '',
      creationDate: new Date(),
      currentState: ProjectCurrentStateEnum.InProgress,
      comment: ''
    }
  });

  const [projectImageForm, setProjectImageForm] = useStateWithCallbackLazy<ProjectImageForm>({});

  let keys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.volume_with_unit') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    if (props.uniqueNumber) {
      updateProjectForm('uniqueNumber', props.uniqueNumber);
      setUniqueNumber(props.uniqueNumber);
    }
    //TODO: clear form
  }, [props.uniqueNumber]);

  useEffect(() => {
    if (props.productsPros.list) {
      setSourceProductList(props.productsPros.list, updatedList => {
        saveButtonDisabled(errors, projectForm, projectImageForm, updatedList);
      });
    }
  }, [props.productsPros.list]);

  /**
   *
   * @param key
   * @param value
   */
  const updateProjectForm = (key: string, value: any, mustValidate?: boolean): void => {
    let project = { ...projectForm.project };

    const formKey = key as keyof any; //TODO: add interface
    project[formKey] = value;

    return setProjectForm(
      { project: project },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    let photo: ProjectImageForm = {
      base64File: base64,
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
      photoCreationDate: new Date()
    };
    setProjectImageForm(photo, projectImageFormUpdated => {
      saveButtonDisabled(errors, projectForm, projectImageFormUpdated);
    });
  };

  const deletePhoto = async () => {
    let photo: ProjectImageForm = {
      base64File: undefined,
      longitude: undefined,
      latitude: undefined
    };
    setProjectImageForm(photo, projectImageFormUpdated => {
      saveButtonDisabled(errors, projectForm, projectImageFormUpdated);
    });
  };

  //Validators
  const validators = useState<any>({
    name: { required: true },
    sumCubage: { numeric: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    sumCubage: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ProjectForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (
    errorsUpdated,
    updatedProjectForm?: ProjectForm,
    updatedImageForm?: ProjectImageForm,
    sourceProductList?: any[]
  ) => {
    let disabled: boolean = false;
    const proj = updatedProjectForm?.project;
    if (!proj || urstammUtilValidatorInError(errorsUpdated) || !proj.name) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const submitForm = () => {
    props.submitProjectForm(projectForm, projectImageForm);
  };

  const getFilteredData = (currentState: string, text: string): any => {
    return sourceProductList.filter(val => val.name.toLowerCase().includes(text.toLowerCase()));
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Project unique number - Ex: UR_2023_0001 */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="project_uniqueNumber"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={projectForm.project.uniqueNumber ? projectForm.project.uniqueNumber : ''}
            onChangeText={(text: string) => {}}
            placeholder={i18n.t('views.project.project_registration.insert_project_unique_number_here')}
            textInputTitle={i18n.t('views.project.project_registration.unique_number')}
            editable={false}
            mandatory={false}
          />

          {/**Validator */}
          {errors['uniqueNumber'] && errors['uniqueNumber'].length > 0
            ? errors['uniqueNumber'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Project name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="project_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={projectForm.project.name ? projectForm.project.name : ''}
            onChangeText={(text: string) => {
              updateProjectForm('name', text, true);
            }}
            placeholder={i18n.t('views.project.project_registration.insert_project_name_here')}
            textInputTitle={i18n.t('views.project.project_registration.project_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Project creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'project_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateProjectForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.project.project_registration.insert_project_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.project.project_registration.choose_products') + getMandatorySymbol(false)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_products'}
            text={i18n.t('views.project.project_registration.choose_products')}
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowSourceProductSelect(true)}
            counter={getCounterBySelected(sourceProductList)}
          />

          {/**Product Modal multiple select */}
          {showSourceProductSelect ? (
            <UrstammModalSelect
              testID={'modal_products_multiple_select'}
              visible={showSourceProductSelect}
              text={{
                textInputTitle: i18n.t('views.project.project_registration.choose_products'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowSourceProductSelect(false),
                  props.productsPros.resetSelected ? props.productsPros.resetSelected() : null;
              }}
              confirmButton={{
                enabled: true,
                text: i18n.t('generics.save'),
                submitValue: () => {
                  setShowSourceProductSelect(false);
                  props.productsPros.confirmSelected ? props.productsPros.confirmSelected() : null;
                }
              }}
              listJSX={
                <UrstammList
                  filterMethod={(currentState: string, text: string) => getFilteredData(currentState, text)}
                  elementSelected={(productSelected: any) => {
                    props.productsPros.selected(productSelected);
                    saveButtonDisabled(errors, projectForm, projectImageForm, sourceProductList);
                  }}
                  showHash={false}
                  testID={'source_product_list'}
                  textForEmptyList={i18n.t('views.project.project_registration.products_not_found')}
                  titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                  list={sourceProductList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  moreItems={props.productsPros.moreItems}
                  filterList={true}
                  multipleSelection={true}
                  filterListFunction={(text, data) =>
                    filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, rdxUserExtendedMe!)
                  }
                  currentState={{
                    //icon: (state) => getIconByLoggingState(state),
                    color: state => getColorByProductState(state),
                    text: state => getTextByProductState(state)
                  }}
                />
              }
            />
          ) : null}
        </View>

        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammFilePicker
            testID="project_image_picker"
            inputStyle={UrstammStyleContainer.cameraContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            icon={{
              showIcon: true,
              iconName: 'camera',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('generics.upload_photo_file')}
            textInputTitle={i18n.t('views.project.project_registration.project_photo')}
            uploadType={UploadType.camera}
            mandatory={false}
            deleteBase64={deletePhoto}
            disabled={false}
            mediaTypeUploaded={PickerMediaType.Image}
            saveBase64={(base64: string, location: any) => {
              updatePhoto(base64, location, true);
            }}
          />

          {/**Validator */}
          {errors['base64File'] && errors['base64File'].length > 0
            ? errors['base64File'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="project_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={projectForm.project.comment ? projectForm.project.comment : ''}
            onChangeText={(text: string) => updateProjectForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_project'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => submitForm()}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>
      </ScrollView>
    </View>
  );
}
