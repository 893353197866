import { PlatformHelper } from '@helpers/PlatformHelper';
import { StyleSheet } from 'react-native';
import { ColorTheme } from './ColorTheme';
import { UrstammFontFamily, UrstammFontsize } from './UrstammFont';

export const UrstammStyleLayout = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    zIndex: 1
  },
  bottomSubContainer40: {
    flex: 1,
    marginVertical: 36,
    marginHorizontal: 40
  },
  topContainerLogin: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  topContainerWelcome: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottomContainerLogin: {
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  baseBottomContainer: {
    flex: 3, // before 3
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  topContainerSignup: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    paddingLeft: 40
  },
  topContainerCorner: {
    flex: 1,
    backgroundColor: 'white',
    height: '100%'
  },
  bottomSubContainerCorner12: {
    flex: 1,
    marginVertical: 36,
    marginHorizontal: 12
  },
  formElementContainerGrw1: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingVertical: 8
  },
  formElementContainerPd8: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingVertical: 8
  },
  formElementContainerPd16: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingVertical: 66
  },
  formElementContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
});

export const UrstammStyleButton = StyleSheet.create({
  baseButtonStructure: {
    flexDirection: 'row',
    //padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    maxHeight: 100,
    minHeight: 56,
    marginBottom: 10
  },
  smallBaseButtonStructure: {
    flexDirection: 'row',
    //padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    maxHeight: 44,
    minHeight: 36,
    marginBottom: 10
  },
  borderLightGreen: {
    borderWidth: 2,
    borderColor: ColorTheme.lightGreen
  },
  borderMainGreen: {
    borderWidth: 1,
    borderColor: ColorTheme.mediumGrey
  },
  borderWarn: {
    borderWidth: 1,
    borderColor: ColorTheme.warn
  },
  baseButtonBWStyle: {
    color: 'white',
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  buttonSelectBWStyle: {
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  baseButtonBWWarnStyle: {
    color: ColorTheme.warn,
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  baseButtonBWLightGreenStyle: {
    color: ColorTheme.lightGreen,
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  mainButtonContainer: {
    marginRight: 8
  },
  buttonContainerFilterActive: {
    minWidth: 80,
    minHeight: 30,
    backgroundColor: ColorTheme.lightGreen,
    borderWidth: 1,
    borderColor: ColorTheme.mainGreen,
    padding: 8,
    borderRadius: 48,
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonContainerFilterInactive: {
    minWidth: 80,
    minHeight: 30,
    backgroundColor: 'white',
    padding: 8,
    borderRadius: 48,
    borderWidth: 1,
    borderColor: ColorTheme.mainGreen,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const UrstammStyleList = StyleSheet.create({
  /**
   * MAIN CONTAINER - VIEW STYLE (mainTopContainerViewStyle)
   */
  mainTopContainerCol: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    marginVertical: 4,
    borderRadius: 8
  },
  mainCardContainerRowCenter: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    marginVertical: 8,
    borderRadius: 8
  },
  mainCardContainerModal: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    marginVertical: 8,
    borderRadius: 8
  },
  /**
   * MAIN TITLE - TEXT STYLE (mainTitleTextStyle)
   */
  cardMainTitleTextStyle: {
    color: ColorTheme.black,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  xCardMainTitleTextStyle: {
    color: ColorTheme.black,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize)
  },
  /**
   *  MAIN BOTTOM CONTAINER - mainBottomContainerViewStyle
   */
  cardSubContainerInlineElements: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  cardSubContainerPDT12: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: 12
  },
  cardSubContainerPDTB8: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: 4,
    paddingBottom: 4
  },
  cardSubContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  pileCardSubContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: 8
  },
  /**
   * SUB DATA CONTAINER - (bottomContainerViewStyle)
   */
  cardSubData: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  /**
   * SUB DATA TEXT STYLE - (bottomDataTextStyle)
   */
  cardSubTitleTextStyle: {
    color: ColorTheme.mainGreen,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  plotCardSubContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardTitleTextStyle: {
    color: ColorTheme.lightGreen,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  treeCardSubContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 12
  }
});

export const UrstammStyleHeader = StyleSheet.create({
  sectionImageContainer: {
    bottom: -32,
    left: 32,
    position: 'absolute',
    justifyContent: 'flex-end',
    alignSelf: 'flex-start'
  },
  topLinesContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },
  bottomLinesContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: ColorTheme.ivory
  },
  headerTextStyleLightGreen: {
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xxxbaseFontSize),
    color: ColorTheme.lightGreen,
    fontFamily: UrstammFontFamily.boldFont
  },
  headerTextStyleBlack: {
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xxbaseFontSize),
    color: ColorTheme.black,
    fontFamily: UrstammFontFamily.boldFont
  },
  headerDetailsTextStyleBlack: {
    fontSize: PlatformHelper.normalizeByDevice(18), //boldFont and regularFont have different size (look: tree details header)
    color: ColorTheme.black,
    fontFamily: UrstammFontFamily.regularFont
  },
  //Header logo (centered)
  headerCenterLogo: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
    //marginTop: -20
  },
  //Header logo + title (flex-start)
  headerLogoTitleStart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    marginTop: 20,
    marginHorizontal: 20
  },
  //Header logo + title (flex-start)
  headerDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    height: '100%',
    marginHorizontal: 20,
    paddingBottom: 8
  },
  headerTitleXBDarkGrey: {
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xxxbaseFontSize),
    color: ColorTheme.lightGreen
  }
});

export const UrstammStyleCornerButton = StyleSheet.create({
  cornerTopRightContainer: {
    position: 'absolute',
    backgroundColor: ColorTheme.mainGreen,
    borderBottomLeftRadius: 144,
    width: 104,
    height: 104,
    overflow: 'hidden'
  },
  textIconTopRightContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 28,
    marginBottom: 8
  },
  cornerTopLeftContainer: {
    position: 'absolute',
    backgroundColor: ColorTheme.mainGreen,
    borderBottomRightRadius: 144,
    width: 104,
    height: 104,
    overflow: 'hidden'
  },
  textIconTopLeftContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 28,
    marginBottom: 8
  },
  cornerBottomLeftContainer: {
    position: 'absolute',
    backgroundColor: ColorTheme.mainGreen,
    borderTopRightRadius: 144,
    width: 124,
    height: 124,
    overflow: 'hidden'
  },
  textIconBottomLeftContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 28,
    marginTop: 8
  },
  cornerBottomRightContainer: {
    position: 'absolute',
    backgroundColor: ColorTheme.mainGreen,
    borderTopLeftRadius: 144,
    width: 124,
    height: 124,
    overflow: 'hidden'
  },
  textIconBottomRightContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    marginTop: 30
  },
  cornerTextStyle: {
    color: 'white',
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.sbaseFontSize),
    textTransform: 'uppercase',
    letterSpacing: 2,
    marginLeft: 3,
    textAlign: 'center'
  },
  cornerTextStyleNoLetterSpacing: {
    color: 'white',
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.sbaseFontSize),
    textTransform: 'uppercase',
    letterSpacing: 0,
    marginLeft: 3,
    textAlign: 'center'
  },
  cornerSubTextStyle: {
    color: ColorTheme.lightGreen,
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.ssbaseFontSize),
    textTransform: 'uppercase',
    letterSpacing: 2,
    marginLeft: 3,
    textAlign: 'center'
  },
  mBottom: {
    marginBottom: -36
  },
  cornerTopLeft: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  cornerTopRight: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  // When bottomSubContainer12 is applied
  cornerBottomLeft12: {
    marginHorizontal: -12,
    justifyContent: 'flex-end'
  },
  cornerBottomRight12: {
    display: 'flex',
    alignItems: 'flex-end',
    marginHorizontal: -12,
    justifyContent: 'flex-end'
  },
  // When bottomSubContainer40 is applied
  cornerBottomLeft40: {
    marginHorizontal: -40,
    justifyContent: 'flex-end'
  },
  cornerBottomRight40: {
    display: 'flex',
    alignItems: 'flex-end',
    marginHorizontal: -40,
    justifyContent: 'flex-end'
  }
});

export const UrstammStyleContainer = StyleSheet.create({
  row1SpaceBetween: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  statusCreationDateTextWithIcon: {
    alignItems: 'center',
    paddingLeft: 8,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  statusCreationDateText: {
    alignItems: 'center',
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  inputContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: ColorTheme.darkGrey,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 4,
    maxHeight: 64,
    minHeight: 64
  },
  inputContainerHighlight: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 2,
    backgroundColor: 'white',
    borderColor: ColorTheme.warn,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 4,
    maxHeight: 64,
    minHeight: 64
  },
  textareaContainer: {
    flex: 1,
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: ColorTheme.darkGrey,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 4,
    maxHeight: 64,
    minHeight: 64
  },
  cameraContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: ColorTheme.darkGrey,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 4,
    maxHeight: 64,
    minHeight: 64
  }
});

export const UrstammStyleSelect = StyleSheet.create({
  selectContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: ColorTheme.darkGrey,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 4,
    maxHeight: 64
  }
});

export const UrstammStyleModal = StyleSheet.create({
  centeredView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  buttonModalContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  textModalContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center'
  },
  formBtextModalStyle: {
    color: ColorTheme.darkGrey,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
    fontFamily: UrstammFontFamily.regularFont
  },
  buttonModal: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    maxHeight: 60,
    minHeight: 60
  }
});

export const UrstammStyle = StyleSheet.create({
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  flex3: {
    flex: 3
  },
  flex4: {
    flex: 4
  },
  flex5: {
    flex: 5
  },
  flexGrow1: {
    flexGrow: 1
  },
  flexColumn: {
    flexDirection: 'column'
  },
  flexRow: {
    flexDirection: 'row'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  flexEndY: {
    alignItems: 'flex-end'
  },
  flexEndX: {
    justifyContent: 'flex-end'
  },
  flexStartY: {
    alignItems: 'flex-start'
  },
  flexCenterY: {
    alignItems: 'center'
  },
  flexCenterX: {
    justifyContent: 'center'
  },
  flexStartX: {
    justifyContent: 'flex-start'
  },
  //**********zIndex
  zIndex1: {
    zIndex: 1,
    elevation: 1
  },
  zIndex2: {
    zIndex: 2,
    elevation: 2
  },
  zIndex3: {
    zIndex: 3,
    elevation: 3
  },
  zIndex4: {
    zIndex: 4,
    elevation: 4
  },
  zIndex5: {
    zIndex: 5,
    elevation: 5
  },
  baseShadow: {
    borderStyle: 'solid',
    elevation: 6,
    shadowColor: '#ededed',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5
  },
  baseShadowList: {
    borderStyle: 'solid',
    elevation: 6,
    shadowColor: '#b8b8b8',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.8,
    shadowRadius: 4
  },
  formBaseLDarkGrey: {
    color: ColorTheme.darkGrey,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    fontFamily: UrstammFontFamily.lightFont
  },
  formBaseRMainGreen: {
    color: ColorTheme.mainGreen,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
    fontFamily: UrstammFontFamily.regularFont
  },
  formValidators: {
    color: ColorTheme.warn,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    fontFamily: UrstammFontFamily.lightFont,
    fontStyle: 'italic'
  },
  sectionTitleXBDarkGrey: {
    fontFamily: UrstammFontFamily.boldFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
    color: ColorTheme.darkGrey
  },
  textTitleContainerFlex: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: 16
  },
  textTitleContainer: {
    justifyContent: 'center',
    marginBottom: 16
  },
  textTitle: {
    color: ColorTheme.black,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize)
    //textTransform: 'capitalize',
  },
  attentionSubTitle: {
    color: ColorTheme.black,
    fontFamily: UrstammFontFamily.lightFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    fontStyle: 'italic'
  },
  titleStyle: {
    color: ColorTheme.lightGreen,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
    //textTransform: 'capitalize'
  },
  dataStyle: {
    color: ColorTheme.mainGreen,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
    //textTransform: 'capitalize'
  },
  switchStyle: {
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  iconContainer: {
    paddingRight: 14
  },
  divider: {
    paddingVertical: 12,
    borderBottomColor: ColorTheme.fineGrey,
    borderBottomWidth: StyleSheet.hairlineWidth
  },
  paddingBottom16: {
    paddingBottom: 16
  },
  paddingBottom4: {
    paddingBottom: 4
  },
  alignSelfFlexEnd: {
    alignSelf: 'flex-end'
  }
});

export const UrstammStyleCollapsible = StyleSheet.create({
  selectMainContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12
  },
  selectCollapsible: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8,
    backgroundColor: 'transparent'
  },
  containerCollapsible: {
    backgroundColor: 'white',
    borderWidth: 1,
    padding: 12
  },
  optionStyle: {
    paddingVertical: 8
  }
});
