import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import DeliverySheetListView from '@components/views/delivery-sheet/DeliverySheetListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { resetTrunkList } from '@redux/features/trunk/trunkSlice';
import { listSize } from '@services/apis/ApiConfiguration';
import { FullAvailableCompanyResourceApi } from '@services/apis/FullAvailableCompanyResourceApi';
import { FullAvailableDeliverySheetResourceApi } from '@services/apis/FullAvailableDeliverySheetResourceApi';
import {
  DeliverySheetDTO,
  GetDeliverySheetsByLoggingDirectionEnum,
  Logging,
  PageCompany,
  PageDeliverySheetDTO,
  Pile
} from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StackEnum } from '../../navigation/UrstammNavigation';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import {
  resetDeliverySheetList,
  setDeliverySheetList,
  setDeliverySheetListSort,
  setDeliverySheetPage,
  setDeliverySheetTotalPages
} from '../../redux/features/delivery-sheet/deliverySheetSlice';
import { resetSawmillList, setSawmillList, setSawmillTotalPages } from '../../redux/features/sawmill/sawmillSlice';
import { RootState } from '../../redux/store';
import { loggingStateIsClosed } from '../../utils/classes/UrstammUtilityCurrentState';
import { loggedUserIsASawmill, urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function DeliverySheetListContainer({ navigation, route }: RootStackScreenProps<'DeliverySheetList'>) {
  //REDUX
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxDSList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.list);
  const rdxDSCompleteList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.completeList);
  const rdxDSPage = useSelector((state: RootState) => state.persistedReducer.deliverySheet.page);
  const rdxDSTotalPages = useSelector((state: RootState) => state.persistedReducer.deliverySheet.totalPages);
  const rdxDSSortBy = useSelector((state: RootState) => state.persistedReducer.deliverySheet.sortBy);
  const rdxSawmillList = useSelector((state: RootState) => state.persistedReducer.sawmill.list);
  const rdxSawmillCompleteList = useSelector((state: RootState) => state.persistedReducer.sawmill.completeList);
  const rdxSawmillPage = useSelector((state: RootState) => state.persistedReducer.sawmill.page);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const dispatch = useDispatch();

  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: route.params?.loggingSelected ? true : false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxDSSortBy.length > 0 && rdxDSSortBy[0]
          ? rdxDSSortBy[0].text
          : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner:
        !loggingStateIsClosed(route.params?.loggingSelected?.currentState) && !loggedUserIsASawmill(rdxUserExtendedMe),
      text: i18n.t('generics.delivery_sheet'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(resetDeliverySheetList());
      dispatch(resetSawmillList());
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      getDeliverySheetList(route.params?.loggingSelected, undefined, rdxDSSortBy);
    }, [route.params, rdxDSPage, rdxDSSortBy])
  );

  useFocusEffect(
    useCallback(() => {
      getAllCompanyConnectedToMe();
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (route.params?.stack == StackEnum.delivery_sheet_stack) {
        navigation.setParams({ loggingSelected: undefined });
      }
    }, [route.params?.stack])
  );

  const getDeliverySheetList = (loggingSelected?: Logging, pileSelected?: Pile, order?: ButtonSelect[]): void => {
    dispatch(changeLoaderStatus(true));

    if (pileSelected || !loggingSelected || loggedUserIsASawmill(rdxUserExtendedMe)) {
      let sortBy =
        order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
      FullAvailableDeliverySheetResourceApi.getMyDeliverySheetsPage(rdxDSCompleteList, {
        sort: sortBy,
        customDeliverySheetCriteria: {},
        page: rdxDSPage,
        size: listSize
      })
        .then((deliverySheetList: PageDeliverySheetDTO) => {
          dispatch(changeLoaderStatus(false));

          if (deliverySheetList) {
            dispatch(setDeliverySheetTotalPages(deliverySheetList.totalPages!));
            dispatch(setDeliverySheetList(deliverySheetList.content));
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    } else {
      let sort = order && order.length > 0 ? order[0].sort?.sortBy : 'creationDate';
      let direction = order && order.length > 0 ? order[0].sort?.direction : 'DESC';

      FullAvailableDeliverySheetResourceApi.getDeliverySheetsByLogging(rdxDSCompleteList, {
        sortBy: sort!,
        direction: direction! as GetDeliverySheetsByLoggingDirectionEnum,
        loggingId: loggingSelected.id!
      })
        .then((deliverySheetList: DeliverySheetDTO[]) => {
          dispatch(changeLoaderStatus(false));

          if (deliverySheetList) {
            dispatch(setDeliverySheetList(deliverySheetList));
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  };

  /**
   * Setting sawmill list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    FullAvailableCompanyResourceApi.getCompaniesConnectedToMe(rdxSawmillCompleteList, {
      sort: sortBy,
      customCompanyCriteria: {},
      page: rdxSawmillPage,
      size: 1000
    })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list) {
          dispatch(setSawmillTotalPages(list.totalPages!));
          dispatch(setSawmillList(list.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getMoreDsItems = (): void => {
    if (rdxDSPage < rdxDSTotalPages - 1) {
      dispatch(setDeliverySheetPage(rdxDSPage + 1));
    }
  };

  const navigateToDeliverySheetRegistration = (): void => {
    UrstammNavigationHelper.navigateToDeliverySheetRegistration(navigation, route.params?.loggingSelected);
  };

  const navigateToDeliverySheetDetails = (deliverySheet: DeliverySheetDTO): void => {
    dispatch(resetTrunkList());
    UrstammNavigationHelper.navigateToDeliverySheetDetails(navigation, deliverySheet);
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setDeliverySheetListSort(orderSelected));
    setShowSortBy(false);
  };

  const sendDeliverySheetToSawmill = async (data: any) => {
    let networkOk = await NetworkHelper.isNetworkOk(false);
    if (!networkOk) return;
    dispatch(changeLoaderStatus(true));

    FullAvailableDeliverySheetResourceApi.updateDeliverySheetCustom(
      rdxOfflineSyncList,
      dispatch,
      addOfflineObjectSync,
      {
        id: data?.id!,
        deliverySheetDTO: { id: data?.id!, sentToSawmill: true }
      }
    )
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          dispatch(resetDeliverySheetList());
          UrstammNavigationHelper.navigateToDeliverySheetList(
            navigation,
            route.params?.deliverySheet?.item?.logging,
            true
          );
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: route.params?.loggingSelected
          ? UrstammStyleHeader.headerCenterLogo
          : UrstammStyleHeader.headerLogoTitleStart,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomRight={navigateToDeliverySheetRegistration}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'delivery_sheet_title'}
          text={i18n.t('generics.delivery_sheet')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={
        route.params?.loggingSelected
          ? [
              {
                title: i18n.t('generics.logging'),
                value: route.params?.loggingSelected.name!
              }
            ]
          : undefined
      }
      view={
        <DeliverySheetListView
          navigation={navigation}
          route={route}
          deliverySheetList={rdxDSList}
          refresh={() => getDeliverySheetList(route.params?.loggingSelected, undefined, rdxDSSortBy)}
          moreItems={getMoreDsItems}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
          validateDSSwitched={data => sendDeliverySheetToSawmill(data)}
          navigateToDeliverySheetDetails={(deliverySheetSelected: DeliverySheetDTO) =>
            navigateToDeliverySheetDetails(deliverySheetSelected)
          }
        />
      }
    />
  );
}
