import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  applySavingToReference,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProductDetailsView, { ProductImageForm } from '@components/views/product/ProductDetailsView';
import { ProductForm } from '@components/views/product/ProductRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import {
  resetCompanyAppearanceList,
  setCompanyAppearanceList
} from '@redux/features/appearance/companyAppearanceSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProcessorBatchList } from '@redux/features/processor-batch/processorBatchSlice';
import { resetProductList } from '@redux/features/product/productSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyAppearanceResourceApi,
  customCompanySurfaceResourceApi,
  customProductResourceApi
} from '@services/apis/ApiConfiguration';
import {
  Company,
  CompanyAppearance,
  CompanySurface,
  ProcessorBatchDTO,
  Product,
  ProductCertification,
  ProductCertificationCertificationEnum,
  ProductCurrentStateEnum,
  ProductDTO,
  ProductSpecies,
  ProductSpeciesSpeciesEnum
} from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard, ScrollView, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { RouteProcessorBatchDetails } from '../../navigation/UrstammNavigationRoutes';
import {
  getColorByProductState,
  getIconByProductState,
  getTextByProductState,
  getTextByTreeOrTrunkSpecies
} from '../../utils/classes/UrstammUtilityCurrentState';
import {
  generateSetForCertification,
  generateSetForProductSpecies,
  getAllCompanyProcessorConnectedToMe,
  getCompanyProductType,
  isCompanyMock,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleCornerButton,
  UrstammStyleHeader,
  UrstammStyleLayout
} from '../../utils/styles/UrstammStyle';

export default function ProductDetailsContainer({ route, navigation }: RootStackScreenProps<'ProductDetails'>) {
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.product'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  //Redux
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyProductTypeCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyProductType.completeList
  );

  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const rdxCompanySurfaceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companySurface.completeList
  );

  const rdxCompanyAppearanceList = useSelector((state: RootState) => state.persistedReducer.companyAppearance.list);
  const rdxCompanyAppearanceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyAppearance.completeList
  );
  const dispatch = useDispatch();

  const [productSelected, setProductSelected] = useState<ProductDTO>();
  const [processorBatchSelected, setProcessorBatchSelected] = useState<ProcessorBatchDTO>();

  const [productSpeciesList, setProductSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [productSpeciesListReference, setProductSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);

  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);

  const [processorList, setProcessorList] = useState<Company[]>([]);

  const readOnly = useMemo(() => {
    return !!route.params?.readOnly;
  }, [!!route.params?.readOnly]);

  useEffect(() => {
    if (route.params?.productSelected && route.params?.processorBatchSelected) {
      getProductCustom(route?.params?.productSelected);
      setProcessorBatchSelected(route.params?.processorBatchSelected);
      getAllCompanyProcessorConnectedToMe(dispatch, setProcessorList);
      getCompanyProductType(dispatch);
      getCompanySurfaceResourceApi();
      getCompanyAppearanceResourceApi();
    }
  }, [route.params]);

  const goBack = async (): Promise<void> => {
    // Get the source
    const returnTo: string = (route.params?.returnTo || '') ?? '';
    switch (returnTo) {
      case 'back':
        Keyboard.dismiss();
        await sleep(PlatformHelper.isIos() ? 300 : 100);
        navigation.goBack();
        break;
      case RouteProcessorBatchDetails:
      default:
        dispatch(resetProcessorBatchList());
        Keyboard.dismiss();
        await sleep(PlatformHelper.isIos() ? 300 : 100);
        UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatchSelected!, true);
        break;
    }
  };

  const getProductCustom = (productSelected: Product) => {
    dispatch(changeLoaderStatus(true));

    customProductResourceApi
      .getProductCustom({ id: productSelected.id! })
      .then((product: ProductDTO) => {
        dispatch(changeLoaderStatus(false));
        if (product) {
          setProductSelected(product);
          setProcessorBatchSelected(readOnly ? product.processorBatch : route.params?.processorBatchSelected);
          getAllProductCertifications(product);
          getAllProductSpecies(product);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyAppearanceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyAppearanceResourceApi
      .getAllCompanyAppearancesForCompany({})
      .then((appearanceList: CompanyAppearance[]) => {
        dispatch(changeLoaderStatus(false));

        if (appearanceList) {
          dispatch(resetCompanyAppearanceList());
          dispatch(setCompanyAppearanceList(appearanceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllProductCertifications = (product: ProductDTO) => {
    let filterList: any[] = [];
    Object.values(ProductCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == ProductCertificationCertificationEnum.Urstamm,
        disabled: state == ProductCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let productCertification = product.productCertifications ? [...product.productCertifications] : [];
    if (productCertification && productCertification.length > 0) {
      productCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllProductSpecies = (product: ProductDTO) => {
    let productSpecies = product.productSpecies ? [...product.productSpecies] : [];

    let filterList: any[] = Object.values(ProductSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      key: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: !!productSpecies.find(p => p.species === state),
      active: !!productSpecies.find(p => p.species === state),
      disabled: false
    }));
    setProductSpeciesList(filterList, () => {});
    setProductSpeciesListReference(filterList, () => {});
  };

  const submitProduct = (data: ProductForm, photo?: ProductImageForm | undefined) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<ProductCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<ProductSpecies> = generateSetForProductSpecies(productSpeciesList);

    let productObj: Product = {
      id: productSelected?.id,
      processorBatch: processorBatchSelected as any,
      currentState: ProductCurrentStateEnum.InProgress,
      uniqueIdentifier: data.product.uniqueIdentifier!,
      name: data.product.name!,
      creationDate: data.product.creationDate,
      comment: data.product.comment,
      processor: data.product.processorCustomName ? undefined : data.product.processor,
      processorCustomName:
        data.product.processorCustomName && data.product.processorCustomName.length > 0
          ? data.product.processorCustomName
          : undefined,
      sumCubage: data.product.sumCubage,
      productCertifications: certificationSet,
      productSpecies: speciesSet,
      productType: data.product.productType,
      surface: data.product.surface,
      appearance: data.product.appearance,
      longitude: photo?.longitude ? Number(photo?.longitude) : data.product.longitude,
      latitude: photo?.latitude ? Number(photo?.latitude) : data.product.latitude,
      photoCreationDate: photo?.photoCreationDate,
      photoFilepath: data.product.photoFilepath,
    };

    customProductResourceApi
      .updateProductCustom({
        id: productSelected?.id!,
        entityWithFileDTOProduct: { entity: productObj, base64File: photo?.base64File == undefined && productObj.photoFilepath == null ? null : photo?.base64File }
      })
      .then(async (product: Product) => {
        dispatch(changeLoaderStatus(false));

        if (product) {
          dispatch(resetProductList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatchSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'product_title'}
          text={i18n.t('generics.product')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'product_details_subtitle'}
          text={route.params?.productSelected?.uniqueIdentifier}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: route.params?.productSelected?.name }]}
      view={
        processorBatchSelected ? (
          <ProductDetailsView
            navigation={navigation}
            processorBatchDTO={processorBatchSelected}
            productSelected={route.params?.productSelected}
            getProductCustom={() => getProductCustom(route.params?.productSelected)}
            submitProductForm={(data: ProductForm, productPhoto?: ProductImageForm) => submitProduct(data, productPhoto)}
            companyProductTypeProps={{
              companyProductTypeList: rdxCompanyProductTypeList
            }}
            companySurfaceProps={{
              companySurfaceList: rdxCompanySurfaceList
            }}
            companyAppearanceProps={{
              companyAppearanceList: rdxCompanyAppearanceList
            }}
            certificationProps={{
              certificationList: certificationList,
              certificationSelected: (data: any) =>
                selectElementFormList(data, setCertificationList, certificationList),
              resetCertificationSelected: () =>
                restoreListToReference(setCertificationList, certificationListReference),
              confirmCertificationSelected: () =>
                applySavingToReference(setCertificationListReference, certificationList)
            }}
            speciesProps={{
              speciesList: productSpeciesList,
              speciesSelected: (data: any) =>
                selectSlideElementFormList(data, setProductSpeciesList, productSpeciesList),
              resetSpeciesSelected: () => restoreListToReference(setProductSpeciesList, productSpeciesListReference),
              confirmSpeciesSelected: () => applySavingToReference(setProductSpeciesListReference, productSpeciesList)
            }}
            processorList={processorList}
            // speciesProps={(data: any) => getSpeciesListId(data, setSpeciesList, speciesList)}
            // allProductSpecies={productSpeciesList}
            readOnly={readOnly}
          />
        ) : (
          <>
            <View style={UrstammStyle.flex1}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 40 }]}>
                <View style={[UrstammStyleLayout.formElementContainer]}>
                  <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                      {route.params?.productSelected &&
                        getIconByProductState(route.params?.productSelected?.currentState as any)}
                      <Text
                        style={[
                          UrstammStyleContainer.statusCreationDateTextWithIcon,
                          getColorByProductState(route.params?.productSelected.currentState)
                        ]}>
                        {getTextByProductState(route.params?.productSelected.currentState!)}
                      </Text>
                    </View>
                    {route.params?.productSelected?.creationDate ? (
                      <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                        <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                        <Text style={UrstammStyle.dataStyle}>
                          {moment(route.params?.productSelected.creationDate).format('DD/MM/yyyy HH:mm')}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={UrstammStyleLayout.formElementContainer}>
                  <View style={UrstammStyleLayout.formElementContainerPd8}>
                    <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                      <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                        <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.volume') + ': '}</Text>
                        <Text style={[UrstammStyle.dataStyle]}>
                          {NumberHelper.roundWithThreeDecimals(route.params?.productSelected?.sumCubage)}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={UrstammStyleLayout.formElementContainerPd8}>
                    <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                      {NumberHelper.roundWithThreeDecimals(route.params?.productSelected?.sumCubage) != 0 &&
                      (route.params?.productSelected?.processor ||
                        route.params?.productSelected?.processorCustomName) ? (
                        <View style={UrstammStyle.flexRow}>
                          <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.receiver') + ': '}</Text>
                          {route.params?.productSelected?.processor &&
                          !isCompanyMock(route.params?.productSelected?.processor) &&
                          route.params?.productSelected?.processor?.name ? (
                            <Text style={UrstammStyle.dataStyle}>{route.params?.productSelected.processor.name}</Text>
                          ) : route.params?.productSelected?.processorCustomName ? (
                            <Text style={UrstammStyle.dataStyle}>
                              {route.params?.productSelected.processorCustomName}
                            </Text>
                          ) : null}
                        </View>
                      ) : null}
                    </View>
                  </View>

                  <View style={UrstammStyleLayout.formElementContainerPd8}>
                    <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                      {route.params?.productSelected?.comment ? (
                        <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                          <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.comment') + ': '}</Text>
                          <Text style={[UrstammStyle.dataStyle]}>{route.params?.productSelected.comment}</Text>
                        </View>
                      ) : null}
                    </View>

                    {/* <View style={UrstammStyleLayout.formElementContainerPd8}>
                      <View
                        style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX, { marginRight: 8 }]}>
                        {photoPreview ? (
                          <View>
                            {photoPreview ? (
                              <TouchableOpacity style={styles.imagePreview} onPress={() => setShowPreview(true)}>
                                <Image source={{ uri: photoPreview }} style={{ width: 52, height: 52 }} />
                              </TouchableOpacity>
                            ) : null}
                          </View>
                        ) : null}
                      </View>
                    </View> */}
                  </View>
                </View>
              </ScrollView>
            </View>
          </>
        )
      }
    />
  );
}
