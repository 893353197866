import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { selectShippingNoteFilter, setShippingNoteFilterList } from '@redux/features/filter/shippingNoteFilterSlice';
import { customShippingNoteResourceApi } from '@services/apis/ApiConfiguration';
import {
  PageShippingNoteDTO,
  ShippingNote,
  ShippingNoteCurrentStateEnum,
  ShippingNoteStateEnumFilterEqualsEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  getColorByShippingNoteState,
  getIconByShippingNoteState,
  getTextByBatchType,
  getTextByShippingNoteState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterShippingNote } from '../../../utils/classes/UrstammUtilityFilter';
import { truncate } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export default function ShippingNoteListView(props: {
  navigation: any;
  shippingNoteList: ShippingNote[];
  moreItems?: () => void;
  modalVisible: boolean;
  shippingNoteSelected?: (shippingNote: ShippingNote) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const shippingNoteFilter = useSelector((state: RootState) => state.persistedReducer.shippingNoteFilter.value);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.shippingNote.sortBy);
  const dispatch = useDispatch();

  const [shippingNoteSelected, setShippingNoteSelected] = useState<ShippingNote>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  let keys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'batch',
      keyId: '1recordingType',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      // dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('views.batch.sum_volume_packets') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.amount_of_packet') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'amountOfPackets',
      keyId: '1amountOfPackets',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.batch') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'batchData',
      keyId: '1batch',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('generics.id') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'id',
      keyId: '1id',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('generics.sawmill') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sawmill',
      secondKey: 'name',
      keyId: '1sawmill'
    }
  ];

  useEffect(() => {
    if (props.modalVisible) {
      setModalVisible(props.modalVisible);
    }
    return () => {};
  }, [props.modalVisible]);

  useEffect(() => {
    getSlideButtonOptionsShippingNoteCurrentStates();
  }, []);

  /**
   * Function used for generating filter buttons
   */
  const getSlideButtonOptionsShippingNoteCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      key: 'currentUrstammState',
      active: true
    };
    filterList.push(type);
    Object.values(ShippingNoteCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByShippingNoteState(state)!,
        key: 'currentState',
        active: false
      };
      filterList.push(type);
    });
    dispatch(setShippingNoteFilterList(filterList));

    return filterList;
  };

  const setShippingNoteCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectShippingNoteFilter(selected));
  };

  const getActiveShippingNoteCurrentState = (): SlideButtonOption[] => {
    return shippingNoteFilter.filter(val => val.active);
  };

  const getFilteredData = (currentState: string, text: string): Promise<any> => {
    let sortBy =
      rdxSortBy && rdxSortBy.length > 0
        ? [rdxSortBy[0].sort?.sortBy + ',' + rdxSortBy[0].sort?.direction]
        : ['creationDate,desc'];

    return customShippingNoteResourceApi
      .getPageShippingNotesForMyCompany({
        sort: sortBy,
        customShippingNoteCriteria: {
          currentState: currentState == '' ? {} : { equals: currentState as ShippingNoteStateEnumFilterEqualsEnum },
          name: { equals: text }
        }
      })
      .then((shippingNoteList: PageShippingNoteDTO) => {
        if (shippingNoteList) {
          return shippingNoteList.content;
        }
      })
      .catch(async error => {
        return [];
      });
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      {props.shippingNoteList && props.shippingNoteList.length > 0 ? (
        <View style={[{ flex: 0.15 }]}>
          <UrstammSlideButton
            testID={'button_status_filter'}
            slideButtonList={shippingNoteFilter}
            slideButtonRotatedTitle={i18n.t('utility_components.filter_button.status')}
            buttonPressed={(selected: SlideButtonOption) => setShippingNoteCurrentState(selected)}
          />
        </View>
      ) : null}

      <View style={[{ flex: 0.85 }]}>
        <UrstammList
          filterMethod={(currentState: string, text: string) => getFilteredData(currentState, text)}
          elementSelected={(data: any) => {
            setShippingNoteSelected(data.item),
              props.shippingNoteSelected ? props.shippingNoteSelected(data.item) : null;
          }}
          showHash={false}
          testID={'shipping_note_list'}
          list={props.shippingNoteList}
          textForEmptyList={i18n.t('views.shipping_note.shipping_note_not_found')}
          filterBy={getActiveShippingNoteCurrentState()}
          filterList={true}
          filterListFunction={(text, data) => filterShippingNote(text, data, rdxUserExtendedMe)}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          moreItems={props.moreItems}
          listSubData={keys}
          currentState={{
            icon: state => getIconByShippingNoteState(state),
            color: state => getColorByShippingNoteState(state),
            text: state => getTextByShippingNoteState(state)!
          }}
          getTextForData={(data, key) => {
            if (key == 'batch' && data) return truncate(getTextByBatchType(data.recordingType), 6);
            else if (key == 'packets' && data) return data.length;
            return null;
          }}
        />
        {props.showSortBy ? (
          <UrstammModalSortList
            testID={'modal_sort'}
            nameSortKey={'id'}
            nameSortKeyAscText={i18n.t('utility_components.modal.sortBy.id_asc')}
            nameSortKeyDescText={i18n.t('utility_components.modal.sortBy.id_desc')}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
