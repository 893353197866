import { i18n } from '@i18n/i18n';
import React, { useEffect, useState } from 'react';
import { Modal, ScrollView, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import Layout from '../../../config/Layout';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  mainGreenButton,
  mainGreenButtonSelectActive,
  warnButton
} from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleLayout, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../button/UrstammButtonBase';
import UrstammButtonSelect, { ButtonSelect } from '../button/UrstammButtonSelect';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';

export enum SortTypeEnum {
  nameAsc = 'NAME_ASC',
  nameDesc = 'NAME_DESC',
  dateAsc = 'DATE_ASC',
  dateDesc = 'DATE_DESC'
}

export default function UrstammModalSortList(props: {
  testID: string;
  visible?: boolean;
  icon?: UrstammIconProps;
  text?: string;
  nameSortKey?: string;
  nameSortKeyAscText?: string;
  nameSortKeyDescText?: string;
  textStyle?: StyleProp<TextStyle>;
  confirm?: (confirm: boolean) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  sortBySelected: ButtonSelect[];
}) {
  const [sortType, setSortType] = useState<ButtonSelect[]>([
    {
      text: props.nameSortKeyAscText ? props.nameSortKeyAscText : i18n.t('utility_components.modal.sortBy.name_asc'),
      sort: {
        direction: 'ASC',
        sortBy: props.nameSortKey ? props.nameSortKey : 'name'
      },
      enum: SortTypeEnum.nameAsc,
      active: false
    },
    {
      text: props.nameSortKeyDescText ? props.nameSortKeyDescText : i18n.t('utility_components.modal.sortBy.name_desc'),
      sort: {
        direction: 'DESC',
        sortBy: props.nameSortKey ? props.nameSortKey : 'name'
      },
      enum: SortTypeEnum.nameDesc,
      active: false
    },
    {
      text: i18n.t('utility_components.modal.sortBy.date_asc'),
      sort: { direction: 'ASC', sortBy: 'creationDate' },
      enum: SortTypeEnum.dateAsc,
      active: false
    },
    {
      text: i18n.t('utility_components.modal.sortBy.date_desc'),
      sort: { direction: 'DESC', sortBy: 'creationDate' },
      enum: SortTypeEnum.dateDesc,
      active: true
    }
  ]);

  useEffect(() => {
    if (props.sortBySelected && props.sortBySelected.length > 0) {
      activeSortType(props.sortBySelected[0].enum);
    }
    return () => {};
  }, [props.sortBySelected]);

  const activeSortType = (sortTypeEnum: SortTypeEnum) => {
    let sortTypeCopy = JSON.parse(JSON.stringify(sortType));
    sortTypeCopy.forEach(type => {
      type.active = type.enum == sortTypeEnum ? true : false;
    });
    setSortType([...sortTypeCopy]);
  };

  const clearSort = () => {
    let sortTypeCopy = JSON.parse(JSON.stringify(sortType));
    sortTypeCopy.forEach(type => {
      type.active = type.enum == SortTypeEnum.dateDesc ? true : false;
    });
    setSortType([...sortTypeCopy]);
    props.applyOrder([
      {
        active: true,
        enum: 'DATE_DESC',
        sort: {
          direction: 'DESC',
          sortBy: 'creationDate'
        },
        text: 'Most recent'
      }
    ]);
  };

  const applyOrder = () => {
    let orderSelected: ButtonSelect[] = sortType.filter(type => type.active);
    props.applyOrder(orderSelected);
  };

  return (
    <View style={UrstammStyleModal.centeredView}>
      <Modal animationType="slide" transparent={true} visible={props.showSortBy}>
        <View style={[{ flex: 1, justifyContent: 'center', alignSelf: 'center' }]}>
          <View style={UrstammStyleModal.modalView}>
            <View style={[styles.modalTopButtonContainer]}>
              {/**Close SortBy Modal */}
              <TouchableOpacity
                style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                onPress={props.closeShowSortBy}>
                <View style={[styles.iconCloseContainer]}>
                  <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                </View>
              </TouchableOpacity>
            </View>

            <View
              style={[
                {
                  height: Layout.window.height * 0.65,
                  width: Layout.window.width * 0.7
                }
              ]}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[UrstammStyleLayout.formElementContainerGrw1]}>
                {/** Sort type */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammButtonSelect
                    testID={'sort_type_button_select'}
                    buttonSelectTitle={i18n.t('utility_components.modal.sortBy.choose_sorting_type')}
                    options={sortType}
                    style={mainGreenButtonSelectActive}
                    onSubmit={(data: ButtonSelect) => {
                      activeSortType(data.enum);
                    }}
                  />
                </View>

                <View
                  style={{
                    borderBottomColor: ColorTheme.fineGrey,
                    borderBottomWidth: StyleSheet.hairlineWidth
                  }}
                />

                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammButtonBase
                    testID={'clear_order'}
                    text={i18n.t('utility_components.modal.sortBy.clear_order')}
                    style={warnButton}
                    onSubmit={clearSort}
                  />

                  <UrstammButtonBase
                    testID={'apply_order'}
                    text={i18n.t('utility_components.modal.sortBy.apply_order')}
                    style={mainGreenButton}
                    onSubmit={() => applyOrder()}
                  />
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  modalTopButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    flex: 1,
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
