import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';
import { customProductResourceApi } from '@services/apis/ApiConfiguration';
import { Company, ProcessorBatchDTO, Product, ProductDTO, ProductDTOCurrentStateEnum } from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Layout from '../../../config/Layout';
import {
  getColorByProductState,
  getIconByProductState,
  getTextByProductState,
  getTextByTreeOrTrunkSpecies
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCounterBySelected,
  getMandatorySymbol,
  isCompanyMock,
  productIsEditable
} from '../../../utils/classes/UrstammUtilityFunctions';
import { buildProductPhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import {
  CertificationProps,
  CompanyAppearanceProps,
  CompanyProductTypeProps,
  CompanySurfaceProps,
  ProductForm,
  SpeciesProps
} from './ProductRegistrationView';

export interface ProductImageForm {
  base64File?: string;
  longitude?: string;
  latitude?: string;
  photoCreationDate?: Date;
}

export default function ProductDetailsView(props: {
  navigation: any;
  processorBatchDTO: ProcessorBatchDTO;
  productSelected: Product;
  submitProductForm: (data: ProductForm, productPhoto?: ProductImageForm) => void;
  companyProductTypeProps?: CompanyProductTypeProps;
  companySurfaceProps?: CompanySurfaceProps;
  companyAppearanceProps?: CompanyAppearanceProps;
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  processorList: Company[];
  closingProduct?: boolean;
  readOnly?: boolean;
  getProductCustom?: any;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const rdxProductList = useSelector((state: RootState) => state.persistedReducer.product.list);
  const [speciesList, setSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [showProductTypeModalSelect, setShowProductTypeModalSelect] = useState<boolean>(false);
  const [showProductWoodQualityModalSelect, setShowProductWoodQualityModalSelect] = useState<boolean>(false);
  const [showSurfaceModalSelect, setShowSurfaceModalSelect] = useState<boolean>(false);
  const [showAppearanceModalSelect, setShowAppearanceModalSelect] = useState<boolean>(false);
  const [showTrunkModalSelect, setShowProductModalSelect] = useState<boolean>(false);
  const [showCertificationModalSelect, setShowCertificationModalSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [showNoUrstammProcessor, setShowNoUrstammProcessor] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<any>(false);
  const [photoPreview, setPhotoPreview] = useState<string>('');
  const [productProcessorCustomName, setProductProcessorCustomName] = useState<string>('');
  const [productProcessorCustomNameReference, setProductProcessorCustomNameReference] = useState<string>('');
  const [showCloseProductConfirm, setShowCloseProductConfirm] = useState<boolean>(false);
  const [sumCubageEntered, setSumCubageEntered] = useStateWithCallbackLazy<string>('');

  const editable = useMemo(() => {
    return !props.readOnly;
  }, [!props.readOnly]);

  const [productForm, setProductForm] = useState<ProductForm>({
    product: {
      id: props.productSelected.id,
      uniqueIdentifier: props.productSelected?.uniqueIdentifier ? props.productSelected?.uniqueIdentifier : '',
      name: '',
      creationDate: new Date(),
      currentState: ProductDTOCurrentStateEnum.InProgress,
      comment: '',
      sumCubage: undefined,
      processorCustomName: ''
    }
  });

  const dispatch = useDispatch();

  const getProductCustom = (productSelected: Product) => {
    dispatch(changeLoaderStatus(true));

    customProductResourceApi
      .getProductCustom({ id: productSelected.id! })
      .then((product: ProductDTO) => {
        dispatch(changeLoaderStatus(false));
        if (product) {
          setProductForm({ product });
          if (product.photoUrl) {
            buildProductPhotoUrl(product).then(res => {
              setPhotoPreview(res);
            });
          }
          // setProcessorBatchSelected(readOnly ? product.processorBatch : route.params?.processorBatchSelected);
          // getAllProductCertifications(product);
          // getAllProductSpecies(product);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  useEffect(() => {
    getProductCustom(props.productSelected);
  }, [props.productSelected]);

  const [productImageForm, setProductImageForm] = useStateWithCallbackLazy<ProductImageForm>({});

  useEffect(() => {
    setSumCubageEntered(
      props.productSelected.sumCubage || props.productSelected.sumCubage == 0
        ? props.productSelected.sumCubage?.toString()
        : '',
      () => {}
    );
    setProductForm(
      {
        product: {
          currentState: ProductDTOCurrentStateEnum.InProgress,
          uniqueIdentifier: props.productSelected?.uniqueIdentifier,
          name: props.productSelected?.name,
          creationDate: props.productSelected?.creationDate,
          comment: props.productSelected?.comment,
          processor: props.productSelected?.processorCustomName
            ? { name: props.productSelected?.processorCustomName }
            : props.productSelected?.processor,
          processorCustomName: props.productSelected?.processorCustomName,
          productType: props.productSelected?.productType,
          surface: props.productSelected?.surface,
          appearance: props.productSelected?.appearance,
          sumCubage: props.productSelected?.sumCubage,
          processorBatch: props.productSelected?.processorBatch,
          photoFilepath: props.productSelected?.photoFilepath,
          latitude: props.productSelected.latitude,
          longitude: props.productSelected.longitude
        }
      },
      productUpdated => {
        saveButtonDisabled(errors, productUpdated, productImageForm, speciesList);
        setProductProcessorCustomName(productUpdated.product?.processorCustomName!);
        setProductProcessorCustomNameReference(productUpdated.product?.processorCustomName!);
      }
    );
  }, [props.productSelected]);

  useEffect(() => {
    if (props.speciesProps?.speciesList)
      setSpeciesList(props.speciesProps?.speciesList, updatedSpeciesList => {
        saveButtonDisabled(errors, productForm, productImageForm, updatedSpeciesList);
      });
  }, [props.speciesProps?.speciesList]);

  /**
   * Can be:
   * NaN: if the sumCubage is not entered or not a valid value
   * 0  : if the value entered is a 0 value. e.g. '0', '0.', '0.0' etc.
   * Number: if the value entered is a non-0 valid number
   */
  const sumCubage = useMemo(() => {
    let psc = sumCubageEntered || '';
    if (psc.trim() === '') {
      return NaN;
    }
    if (psc.includes(',')) {
      psc = psc.replace(/,/gi, '.');
    }
    if (psc.endsWith('.')) {
      psc += '0';
    }
    return Number(psc);
  }, [sumCubageEntered]);

  const sumCubageIsZero = useMemo(() => {
    return sumCubage === 0;
  }, [sumCubage]);

  const showHiddenFields = useMemo(() => {
    return !sumCubageIsZero;
  }, [sumCubage]);

  /**
   *
   * @param key
   * @param value
   */
  const updateProductForm = (key: string, value: any, mustValidate?: boolean): void => {
    let product = { ...productForm.product };

    const formKey = key as keyof any; //TODO: add interface
    product[formKey] = value;

    return setProductForm(
      { product: product },
      mustValidate
        ? updatedLoggingForm => {
            validate(value, key, updatedLoggingForm);
          }
        : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    let photo: ProductImageForm = {
      base64File: base64,
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
      photoCreationDate: new Date()
    };
    setProductImageForm(photo, productImageFormUpdated => {
      saveButtonDisabled(errors, productForm, productImageFormUpdated, speciesList);
    });
  };

  const updateSumCubage = (sumCubage: string) => {
    if (sumCubage === '') {
      setSumCubageEntered(sumCubage, updatedSumCubage => {
        const prod = productForm.product as any;
        prod.sumCubage = updatedSumCubage;
        productForm.product = prod;
        updateProductForm('sumCubage', undefined, true);
      });
    } else {
      setSumCubageEntered(sumCubage.toString(), updatedSumCubage => {
        const prod = productForm.product as any;
        prod.sumCubage = updatedSumCubage;
        productForm.product = prod;
        updateProductForm('sumCubage', updatedSumCubage, true);
      });
    }
  };

  const deletePhoto = async () => {
    let product = { ...productForm.product };
    product.photoFilepath = null;
    product.latitude = null;
    product.longitude = null;
    setProductForm({ product });
    let photo: ProductImageForm = {
      base64File: undefined,
      longitude: undefined,
      latitude: undefined
    };
    setProductImageForm(photo, productImageFormUpdated => {
      saveButtonDisabled(errors, productForm, productImageFormUpdated, speciesList);
    });
  };

  //Validators
  const validators = useMemo(
    () =>
      !props.closingProduct
        ? {
            name: { required: true },
            sumCubage: { numeric: true }
          }
        : {
            name: { required: true },
            productType: { required: !sumCubageIsZero },
            sumCubage: { required: true, numeric: true },
            //species: { required: true },
            processor: { required: !sumCubageIsZero },
            base64File: { required: !sumCubageIsZero }
          },
    [props.closingProduct, sumCubageIsZero]
  );

  const [errors, setErrors] = useStateWithCallbackLazy<any>(
    !props.closingProduct
      ? {
          name: [],
          sumCubage: []
        }
      : {
          name: [],
          productType: [],
          sumCubage: [],
          //species: [],
          processor: [],
          base64File: []
        }
  );

  const validate = (value: string, key: string, updatedTreeForm: ProductForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm, productImageForm, speciesList)
    );
  };

  const saveButtonDisabled = (
    errorsUpdated,
    updatedProductForm?: ProductForm,
    updatedImageForm?: ProductImageForm,
    speciesList?: any[]
  ) => {
    let disabled: boolean = false;
    const prod = updatedProductForm?.product;
    if (props.closingProduct) {
      const selectedSpecies = speciesList?.filter(sp => sp?.active === true) || [];
      if (
        !prod ||
        urstammUtilValidatorInError(errorsUpdated) ||
        !prod.name ||
        (!sumCubageIsZero && !prod.processor) ||
        (!sumCubageIsZero && !prod.productType?.name) ||
        (!sumCubageIsZero && selectedSpecies.length === 0) ||
        (!sumCubageIsZero && updatedImageForm?.base64File?.length == 0) ||
        (!sumCubageIsZero && !updatedImageForm?.base64File)
      ) {
        disabled = true;
      }
    } else {
      if (!prod || urstammUtilValidatorInError(errorsUpdated) || !prod.name) {
        disabled = true;
      }
    }

    return setSaveDisabled(disabled);
  };

  const updateProcessor = (processor: Company) => {
    if (processor.id == -200) {
      setShowProcessorModalSelect(false);
      return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    }
    productForm.product.processorCustomName = '';
    updateProductForm('processor', processor, props.closingProduct);
    return setShowProcessorModalSelect(false);
  };

  const updateProductSpecies = (species: SlideButtonOption) => {
    props.speciesProps?.speciesSelected({ item: species });
  };

  const submitForm = () => {
    props.speciesProps?.confirmSpeciesSelected();
    props.submitProductForm(productForm, productImageForm);
  };

  const printCertificationList = (product: Product | ProductDTO | undefined): string => {
    const certifications =
      product?.productCertifications instanceof Set
        ? [...product?.productCertifications.values()]
        : [...(product?.productCertifications || [])];
    return (
      certifications
        .filter(c => !!c.certification)
        .map(c => c.certification!.toString())
        .reduce((val, next) => {
          return val.length > 0 ? `${val}, ${next}` : next;
        }, '') || ''
    );
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 40 }]}>
        {/* Preview modal */}
        {showPreview && (
          <View style={UrstammStyleModal.centeredView}>
            <Modal animationType="slide" transparent={true} visible={showPreview}>
              <View style={UrstammStyleModal.centeredView}>
                <View style={UrstammStyleModal.modalView}>
                  <View style={[styles.cameraTopButton]}>
                    {/**Close Preview */}
                    <TouchableOpacity
                      style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                      onPress={() => setShowPreview(false)}>
                      <View style={[styles.iconCloseContainer]}>
                        <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                      </View>
                    </TouchableOpacity>
                  </View>

                  {photoPreview.length > 0 && (
                    <Image
                      source={{ uri: photoPreview }}
                      style={{
                        width: Layout.window.width * 0.9,
                        height: Layout.window.height * 0.7
                      }}
                    />
                  )}
                </View>
              </View>
            </Modal>
          </View>
        )}

        {/**Status and creation date */}
        <View style={[UrstammStyleLayout.formElementContainer]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {props.productSelected?.currentState && (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {props.productSelected && getIconByProductState(props.productSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByProductState(props.productSelected.currentState)
                  ]}>
                  {getTextByProductState(props.productSelected.currentState!)}
                </Text>
              </View>
            )}
            {props.productSelected?.creationDate ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(props.productSelected.creationDate).format('DD/MM/yyyy HH:mm')}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {!props.closingProduct ? (
          <View style={UrstammStyleLayout.formElementContainer}>
            <View style={UrstammStyleLayout.formElementContainerPd8}>
              <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.volume') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>
                    {NumberHelper.roundWithThreeDecimals(props.productSelected?.sumCubage)}
                  </Text>
                </View>
                {NumberHelper.roundWithThreeDecimals(props.productSelected?.sumCubage) != 0 &&
                props.productSelected?.productCertifications &&
                false /* never show as the productCertifications was always undefined */ ? (
                  <View style={UrstammStyle.flexRow}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.certifications') + ': '}</Text>
                    <Text style={UrstammStyle.dataStyle}>{printCertificationList(props.productSelected)}</Text>
                  </View>
                ) : null}
              </View>
            </View>

            <View style={UrstammStyleLayout.formElementContainerPd8}>
              <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                {rdxProductList ? (
                  <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.sender') + ': '}</Text>
                    <Text style={[UrstammStyle.dataStyle]}>{productForm.product.processorBatch?.processor?.name}</Text>
                  </View>
                ) : null}
                {NumberHelper.roundWithThreeDecimals(props.productSelected?.sumCubage) != 0 &&
                (props.productSelected?.processor || props.productSelected?.processorCustomName) ? (
                  <View style={UrstammStyle.flexRow}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.receiver') + ': '}</Text>
                    {props.productSelected?.processor &&
                    !isCompanyMock(props.productSelected?.processor) &&
                    props.productSelected?.processor?.name ? (
                      <Text style={UrstammStyle.dataStyle}>{props.productSelected.processor.name}</Text>
                    ) : props.productSelected?.processorCustomName ? (
                      <Text style={UrstammStyle.dataStyle}>{props.productSelected.processorCustomName}</Text>
                    ) : null}
                  </View>
                ) : null}
              </View>
            </View>

            <View style={UrstammStyleLayout.formElementContainerPd8}>
              <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                {productForm.product?.comment ? (
                  <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.comment') + ': '}</Text>
                    <Text style={[UrstammStyle.dataStyle]}>{productForm.product.comment}</Text>
                  </View>
                ) : null}
              </View>

              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX, { marginRight: 8 }]}>
                  {photoPreview ? (
                    <View>
                      {photoPreview ? (
                        <TouchableOpacity style={styles.imagePreview} onPress={() => setShowPreview(true)}>
                          <Image source={{ uri: photoPreview }} style={{ width: 52, height: 52 }} />
                        </TouchableOpacity>
                      ) : null}
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        ) : null}
        <View style={UrstammStyle.divider} />

        {/**Edit DS Collapsible */}
        {props.productSelected && editable && productIsEditable(props.productSelected) ? (
          <View style={{ marginTop: 8 }}>
            <View
              style={[
                UrstammStyleButton.smallBaseButtonStructure,
                { backgroundColor: ColorTheme.yellow },
                UrstammStyle.baseShadow
              ]}>
              <View style={UrstammStyleCollapsible.selectMainContainer}>
                <TouchableOpacity
                  style={UrstammStyleCollapsible.selectCollapsible}
                  onPress={() => setCollapsed(!collapsed)}
                  disabled={props.closingProduct}>
                  <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                  <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
                </TouchableOpacity>
              </View>
            </View>

            <View style={[{ marginTop: 4 }]}>
              <Collapsible
                duration={500}
                easing={'easeInBounce'}
                collapsed={props.closingProduct ? false : collapsed}
                key={sumCubageIsZero ? 'with_zero_sum_cubage' : 'without_zero_sum_cubage'}
                style={UrstammStyleCollapsible.containerCollapsible}>
                <ScrollView
                  keyboardShouldPersistTaps="always"
                  contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
                  {/** Product name */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="product_name"
                      style={{
                        containerViewStyle: UrstammStyleContainer.inputContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={productForm.product.name ? productForm.product.name : ''}
                      onChangeText={(text: string) => {
                        updateProductForm('name', text, true);
                      }}
                      placeholder={i18n.t('views.product.product_registration.insert_product_name_here')}
                      textInputTitle={i18n.t('views.product.product_registration.product_name')}
                      mandatory={true}
                      editable={productIsEditable(props.productSelected)}
                    />

                    {/**Validator */}
                    {errors['name'] && errors['name'].length > 0
                      ? errors['name'].map((error, idx) => {
                          return urstammUtilDisplayError(error, idx);
                        })
                      : null}
                  </View>

                  {/** Product type list */}
                  {showHiddenFields ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t('generics.productType') + getMandatorySymbol(props.closingProduct!)}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_product_type'}
                        text={
                          productForm.product.productType?.name
                            ? productForm.product.productType?.name
                            : i18n.t('views.product.product_registration.choose_product_type')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowProductTypeModalSelect(true)}
                        disabled={!productIsEditable(props.productSelected)}
                      />

                      {/**Product product type  */}
                      {showProductTypeModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_product_company_product_type'}
                          visible={showProductTypeModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.product.product_registration.choose_product_type'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowProductTypeModalSelect(false);
                          }}
                          //confirmButton={{ enabled: false, text: i18n.t('views.product.product_registration.add_new_product'), submitValue: () => { setProductType((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowProductTypeModalSelect(false), setTimeout(() => setOpenUpdateCreateProductType(true), 300) }) } }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              showHash={false}
                              elementSelected={(productType: any) => {
                                updateProductForm('productType', productType.item, props.closingProduct);
                                setShowProductTypeModalSelect(false);
                              }}
                              testID={'products_type_list'}
                              textForEmptyList={i18n.t('views.product.products_type_not_found')}
                              list={props.companyProductTypeProps?.companyProductTypeList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              moreItems={props.companyProductTypeProps?.getMoreProductType}
                              filterList={true}
                              multipleSelection={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** SumCubage */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="product_sumCubage"
                      style={{
                        containerViewStyle: UrstammStyleContainer.inputContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={sumCubageEntered}
                      onChangeText={(text: string) => updateSumCubage(text)}
                      placeholder={i18n.t('views.product.product_registration.insert_sum_cubage_here')}
                      textInputTitle={i18n.t('views.product.product_registration.sum_cubage')}
                      mandatory={!!props.closingProduct}
                      keyboardType={'numeric'}
                      editable={productIsEditable(props.productSelected)}
                    />

                    {/**Validator */}
                    {errors['sumCubage'] && errors['sumCubage'].length > 0
                      ? errors['sumCubage'].map((error, idx) => {
                          return urstammUtilDisplayError(error, idx);
                        })
                      : null}
                  </View>

                  {/**Product species */}
                  {showHiddenFields ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammSlideButton
                        testID={'button_tree_species'}
                        slideButtonList={speciesList}
                        slideButtonTitle={i18n.t('generics.tree_species')}
                        slideButtonRotatedTitle={i18n.t('generics.species')}
                        mandatory={props.closingProduct}
                        buttonPressed={(selected: SlideButtonOption) => {
                          updateProductSpecies(selected);
                        }}
                        disabled={!productIsEditable(props.productSelected)}
                      />
                    </View>
                  ) : null}

                  {/**appearance list */}

                  {showHiddenFields ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.appearance')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_appearance'}
                        text={
                          productForm.product.appearance?.name
                            ? productForm.product.appearance?.name
                            : i18n.t('views.product.product_registration.choose_appearance')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowAppearanceModalSelect(true)}
                        disabled={!productIsEditable(props.productSelected)}
                      />

                      {/**Product Modal multiple select */}
                      {showAppearanceModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_product_company_choose_appearance'}
                          visible={showAppearanceModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.product.product_registration.choose_appearance'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowAppearanceModalSelect(false);
                          }}
                          //confirmButton={{ enabled: false, text: i18n.t('views.product.product_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(appearance: any) => {
                                updateProductForm('appearance', appearance.item, false);
                                setShowAppearanceModalSelect(false);
                              }}
                              showHash={false}
                              testID={'appearance_list'}
                              textForEmptyList={i18n.t('views.product.appearances_not_found')}
                              list={props.companyAppearanceProps?.companyAppearanceList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              moreItems={props.companyAppearanceProps?.getMoreAppearance}
                              filterList={true}
                              multipleSelection={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Surface list */}

                  {showHiddenFields ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.surface')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_surface'}
                        text={
                          productForm.product.surface?.name
                            ? productForm.product.surface?.name
                            : i18n.t('views.product.product_registration.choose_surface')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowSurfaceModalSelect(true)}
                        disabled={!productIsEditable(props.productSelected)}
                      />

                      {/**Product Modal multiple select */}
                      {showSurfaceModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_product_company_choose_surface'}
                          visible={showSurfaceModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.product.product_registration.choose_surface'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowSurfaceModalSelect(false);
                          }}
                          //confirmButton={{ enabled: false, text: i18n.t('views.product.product_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(surface: any) => {
                                updateProductForm('surface', surface.item, false);
                                setShowSurfaceModalSelect(false);
                              }}
                              showHash={false}
                              testID={'surface_list'}
                              textForEmptyList={i18n.t('views.product.surfaces_not_found')}
                              list={props.companySurfaceProps?.companySurfaceList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              moreItems={props.companySurfaceProps?.getMoreSurface}
                              filterList={true}
                              multipleSelection={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Certification list */}
                  {showHiddenFields ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t('views.product.product_registration.choose_certification')}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_certification'}
                        text={i18n.t('views.product.product_registration.choose_certification')}
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowCertificationModalSelect(true)}
                        counter={getCounterBySelected(props.certificationProps?.certificationList)}
                        disabled={!productIsEditable(props.productSelected)}
                      />

                      {/**Trunk Modal multiple select */}
                      {showCertificationModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_certification_multiple_select'}
                          visible={showCertificationModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.product.product_registration.choose_certification'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowCertificationModalSelect(false);
                            props.certificationProps?.resetCertificationSelected();
                          }}
                          confirmButton={{
                            enabled: true,
                            text: i18n.t('generics.save'),
                            submitValue: () => {
                              setShowCertificationModalSelect(false);
                              props.certificationProps?.confirmCertificationSelected();
                            }
                          }}
                          listJSX={
                            <UrstammList
                              elementSelected={(certificationSelected: any) =>
                                props.certificationProps?.certificationSelected(certificationSelected)
                              }
                              showHash={false}
                              testID={'certification_list'}
                              textForEmptyList={i18n.t('views.product.certifications_not_found')}
                              list={props.certificationProps?.certificationList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              moreItems={props.certificationProps?.getMoreCertifications}
                              filterList={false}
                              multipleSelection={true}
                              //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                              titleKey={'name'}
                              getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Processor list */}
                  {showHiddenFields ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t('generics.processor') + getMandatorySymbol(props.closingProduct!)}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_product_type'}
                        text={
                          productForm.product.processor
                            ? productForm.product.processor.name
                            : i18n.t('views.product.product_registration.choose_processor')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowProcessorModalSelect(true)}
                        disabled={!productIsEditable(props.productSelected)}
                      />

                      {/**Product processor  */}
                      {showProcessorModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_product_company_processor'}
                          visible={showProcessorModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.product.product_registration.choose_processor'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowProcessorModalSelect(false);
                          }}
                          confirmButton={{
                            enabled: false,
                            text: i18n.t('views.product.no_urstamm_processor'),
                            submitValue: () => {}
                          }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(processor: any) => {
                                updateProcessor(processor.item);
                              }}
                              showHash={false}
                              testID={'processor_list'}
                              textForEmptyList={i18n.t('views.product.processors_not_found')}
                              list={props.processorList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              filterList={true}
                              multipleSelection={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/**Image picker */}
                  {
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammFilePicker
                        testID="product_image_picker"
                        inputStyle={UrstammStyleContainer.cameraContainer}
                        fontStyle={UrstammStyle.formBaseLDarkGrey}
                        icon={{
                          showIcon: true,
                          iconName: 'camera',
                          iconColor: ColorTheme.lightGreen
                        }}
                        uri={photoPreview}
                        locationProps={productForm.product}
                        placeholder={i18n.t('generics.upload_photo_file')}
                        textInputTitle={i18n.t('views.product.product_registration.product_photo')}
                        uploadType={UploadType.camera}
                        mandatory={false}
                        deleteBase64={() => deletePhoto()}
                        disabled={!productIsEditable(props.productSelected)}
                        mediaTypeUploaded={PickerMediaType.Image}
                        saveBase64={(base64: string, location: any) => {
                          updatePhoto(base64, location, true);
                        }}
                      />

                      {/**Validator */}
                      {errors['base64File'] && errors['base64File'].length > 0
                        ? errors['base64File'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>
                  }

                  {/** Comment */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="product_comment"
                      style={{
                        containerViewStyle: UrstammStyleContainer.textareaContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={productForm.product.comment ? productForm.product.comment : ''}
                      onChangeText={(text: string) => updateProductForm('comment', text, false)}
                      placeholder={i18n.t('generics.insert_comment_here')}
                      textInputTitle={i18n.t('generics.comment')}
                      mandatory={false}
                      multiline={true}
                      editable={productIsEditable(props.productSelected)}
                    />
                  </View>

                  {/**Save */}
                  {productIsEditable(props.productSelected) ? (
                    <View style={[UrstammStyleLayout.formElementContainerPd16]}>
                      <UrstammButtonBase
                        testID={'save_product'}
                        text={i18n.t('generics.save')}
                        style={mainGreenButton}
                        onSubmit={() => (props.closingProduct ? setShowCloseProductConfirm(true) : submitForm())}
                        disabled={saveDisabled || rdxLoaderStatus}
                      />
                    </View>
                  ) : null}

                  {showNoUrstammProcessor ? (
                    <UrstammModalSelect
                      testID={'modal_no_processor_urstamm'}
                      visible={showNoUrstammProcessor}
                      text={{
                        textInputTitle: i18n.t('views.product.product_registration.enter_processor'),
                        textStyle: UrstammStyle.textTitle
                        //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                      }}
                      closeModal={() => {
                        setProductProcessorCustomName(productProcessorCustomNameReference);
                        updateProductForm('processorCustomName', productProcessorCustomNameReference, false);
                        setShowNoUrstammProcessor(false);
                      }}
                      modalSize={{ mainContainerWidth: 0.74, mainContainerHeight: 0.4, secondaryContainerHeight: 0.2 }}
                      confirmButton={{
                        enabled: true,
                        text: i18n.t('generics.save'),
                        submitValue: () => {
                          setProductProcessorCustomNameReference(productProcessorCustomName);
                          updateProductForm(
                            'processor',
                            { name: productForm.product.processorCustomName },
                            props.closingProduct
                          );
                          setShowNoUrstammProcessor(false);
                          setShowProcessorModalSelect(false);
                        }
                      }}
                      listJSX={
                        <>
                          <UrstammInput
                            testID="product_no_urstamm_processor"
                            style={{
                              containerViewStyle: UrstammStyleContainer.textareaContainer,
                              textStyle: UrstammStyle.formBaseLDarkGrey
                            }}
                            icon={{ showIcon: false }}
                            value={
                              productForm.product.processorCustomName ? productForm.product.processorCustomName : ''
                            }
                            onChangeText={(text: string) => {
                              updateProductForm('processorCustomName', text, false);
                              setProductProcessorCustomName(text);
                            }}
                            placeholder={i18n.t('views.product.product_registration.insert_processor_name_address')}
                            textInputTitle={i18n.t('generics.processor')}
                            mandatory={false}
                            multiline={true}
                            editable={productIsEditable(props.productSelected)}
                          />
                        </>
                      }
                    />
                  ) : null}

                  {showCloseProductConfirm && (
                    <UrstammModal
                      testID={'close_logging_modal'}
                      text={i18n.t('views.product.are_you_sure_want_close_product')}
                      textStyle={UrstammStyleModal.formBtextModalStyle}
                      visible={showCloseProductConfirm}
                      buttonText={{
                        left: i18n.t('utility_components.modal.cancel'),
                        right: i18n.t('utility_components.modal.confirm')
                      }}
                      confirm={(confirmed: boolean) => {
                        confirmed ? submitForm() : null, setShowCloseProductConfirm(false);
                      }}
                    />
                  )}
                </ScrollView>
              </Collapsible>
            </View>
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  imagePreview: {
    flex: 1,
    justifyContent: 'center'
  },
  cameraTopButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
