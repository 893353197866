import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import {
  Company,
  DeliverySheetCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTORecordingTypeEnum,
  Logging,
  LoggingCurrentStateEnum,
  LoggingDTO,
  Pile
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByLoggingState,
  getIconByLoggingState,
  getTextByLoggingState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { getMandatorySymbol } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
  mainGreenButtonSelectActive
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

export interface DeliverySheetRegistration {
  id?: number;
  name?: string;
  creationDate?: Date;
  comment?: string;
  logging?: Logging;
  pile?: Pile;
  recordingType?: DeliverySheetDTORecordingTypeEnum;
  forestry?: Company;
  sawmill?: Company;
  currentState?: DeliverySheetCurrentStateEnum;
  currentUrstammState?: DeliverySheetCurrentUrstammStateEnum;
  amountOfTruckJourneys?: number;
}

export interface DeliverySheetForm {
  deliverySheet: DeliverySheetRegistration;
}

export default function DeliverySheetRegistrationView(props: {
  navigation: any;
  submitDeliverySheetForm: (form: any) => void;
  moreItemsLogging: () => void;
  loggingList: LoggingDTO[];
  loggingSelected?: Logging;
  pileList: Pile[];
  pileSelected?: Pile;
  sawmillList?: Company[];
  moreItemsSawmill: () => void;
  getPileListByLoggingId: (loggingSelected: Logging) => void;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const rdxSawmillList = useSelector((state: RootState) => state.persistedReducer.sawmill.list);

  const [filterList, setFilterList] = useState<SlideButtonOption[]>([]);

  const [showLoggingListSelect, setShowLoggingListSelect] = useState<boolean>(false);
  const [showPileListSelect, setShowPileListSelect] = useState<boolean>(false);
  const [showSawmillListSelect, setShowSawmillListSelect] = useState<boolean>(false);

  const [deliverySheetForm, setDeliverySheetForm] = useStateWithCallbackLazy<DeliverySheetForm>({
    deliverySheet: {
      name: '',
      creationDate: new Date(),
      comment: '',
      logging: undefined,
      amountOfTruckJourneys: undefined
    }
  });

  const [deliverySheetRecordingType, setDeliverySheetRecordingType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('views.tree.multiple_tree'),
      enum: DeliverySheetDTORecordingTypeEnum.Multiple,
      active: false
    },
    {
      text: i18n.t('views.tree.date'),
      enum: DeliverySheetDTORecordingTypeEnum.Date,
      active: false
    },
    {
      text: i18n.t('views.tree.single_tree'),
      enum: DeliverySheetDTORecordingTypeEnum.Single,
      active: false
    }
  ]);

  let keys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
    }
  ];

  useEffect(() => {
    getSlideButtonOptionsDSCurrentStates();
  }, []);

  useEffect(() => {
    if (props.loggingSelected) {
      updateDeliverySheetForm('logging', props.loggingSelected, true);
      props.getPileListByLoggingId(props.loggingSelected);
    }
  }, [props.loggingSelected]);

  const getSlideButtonOptionsDSCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    Object.values(LoggingCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByLoggingState(state),
        active: false
      };
      filterList.push(type);
    });
    filterList[0].active = true;
    setFilterList(filterList);
    return filterList;
  };

  /**
   * FORM UPDATE
   */
  const updateDeliverySheetForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, deliverySheetForm, 'deliverySheet');
    setDeliverySheetForm(
      { deliverySheet: form['deliverySheet'] },
      mustValidate ? updatedDeliverySheetForm => validate(value, key, updatedDeliverySheetForm) : () => {}
    );
  };

  const setLoggingFromList = (loggingSelected: Logging) => {
    updateDeliverySheetForm('logging', loggingSelected, true);
    setShowLoggingListSelect(false);
    props.getPileListByLoggingId(loggingSelected!);
  };

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const validators = useState<any>({
    name: { required: true },
    logging: { required: true },
    pile: { required: true },
    recordingType: { required: true },
    amountOfTruckJourneys: { numeric: true, max3digits: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    logging: [],
    pile: [],
    recordingType: [],
    amountOfTruckJourneys: []
  });

  const validate = (value: string, key: string, updatedDeliverySheetForm: DeliverySheetForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedDeliverySheetForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedDeliverySheetForm: DeliverySheetForm) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      updatedDeliverySheetForm.deliverySheet.name?.length == 0 ||
      updatedDeliverySheetForm.deliverySheet.pile == undefined ||
      updatedDeliverySheetForm.deliverySheet.recordingType == undefined ||
      updatedDeliverySheetForm.deliverySheet.logging == undefined
    ) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const loggingIsSelected = () => {
    if (deliverySheetForm.deliverySheet.logging || props.loggingSelected) return true;
    return false;
  };

  const activeDeliverySheetRecordingType = (
    DeliverySheetDTORecordingTypeEnum: DeliverySheetDTORecordingTypeEnum
  ): void => {
    updateDeliverySheetForm('recordingType', DeliverySheetDTORecordingTypeEnum, true);

    deliverySheetRecordingType.forEach(type => {
      type.active = type.enum == DeliverySheetDTORecordingTypeEnum ? true : false;
    });
    setDeliverySheetRecordingType([...deliverySheetRecordingType]);
  };

  const getFilteredData = (currentState: string, text: string): any => {
    return rdxSawmillList.filter(val => val?.name?.toLowerCase().includes(text.toLowerCase()));
  };

  const getFilteredLoggingData = (currentState: string, text: string): any => {
    return props.loggingList.filter(val => val?.name?.toLowerCase().includes(text.toLowerCase()));
  };

  const getFilteredPileData = (currentState: string, text: string): any => {
    return props.pileList.filter(val => val?.name?.toLowerCase().includes(text.toLowerCase()));
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Name */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammInput
            testID="delivery_sheet_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => {
              updateDeliverySheetForm('name', text, true);
            }}
            placeholder={i18n.t('views.delivery_sheet.delivery_sheet_registration.insert_delivery_sheet_name_here')}
            textInputTitle={i18n.t('generics.name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Date Picker */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'delivery_sheet_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateDeliverySheetForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.delivery_sheet.delivery_sheet_registration.insert_delivery_sheet_date_here')}
            mode={'date'}
          />
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="delivery_sheet_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => updateDeliverySheetForm('comment', text)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/** Amount of truck journey */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="delivery_sheet_amount_of_truck_journeys"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            value={
              deliverySheetForm.deliverySheet.amountOfTruckJourneys ||
              deliverySheetForm.deliverySheet.amountOfTruckJourneys == 0
                ? deliverySheetForm.deliverySheet.amountOfTruckJourneys.toString()
                : ''
            }
            icon={{ showIcon: false }}
            onChangeText={(text: Number) => updateDeliverySheetForm('amountOfTruckJourneys', text, true)}
            placeholder={i18n.t(
              'views.delivery_sheet.delivery_sheet_registration.insert_amount_of_truck_journeys_here'
            )}
            textInputTitle={i18n.t('views.delivery_sheet.delivery_sheet_registration.amount_of_truck_journeys')}
            keyboardType={'numeric'}
            mandatory={false}
          />
        </View>

        {/** Logging list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_logging') + getMandatorySymbol(true)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_logging'}
            text={
              deliverySheetForm.deliverySheet.logging?.name
                ? deliverySheetForm.deliverySheet.logging?.name
                : props.loggingSelected
                ? props.loggingSelected.name
                : i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_logging')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowLoggingListSelect(true)}
            disabled={props.loggingSelected ? true : false}
          />

          {/**Logging Modal select */}
          {showLoggingListSelect ? (
            <UrstammModalSelect
              testID={'modal_logging_select'}
              visible={showLoggingListSelect}
              text={{
                textInputTitle: i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_logging'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => setShowLoggingListSelect(false)}
              listJSX={
                <UrstammList
                  filterMethod={(currentState: string, text: string) => getFilteredLoggingData(currentState, text)}
                  elementSelected={(loggingSelected: any) => {
                    setLoggingFromList(loggingSelected.item);
                  }}
                  testID={'logging_list'}
                  textForEmptyList={i18n.t('views.logging.loggings_not_found')}
                  list={props.loggingList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainCardContainerRowCenter,
                    mainTitleTextStyle: UrstammStyleList.cardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  titleTruncateMaxLength={15}
                  filterBy={undefined}
                  moreItems={props.moreItemsLogging}
                  filterList={true}
                  currentState={{
                    icon: state => getIconByLoggingState(state),
                    color: state => getColorByLoggingState(state),
                    text: state => getTextByLoggingState(state)
                  }}
                  showHash={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Pile list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_pile') + getMandatorySymbol(true)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_pile'}
            text={
              deliverySheetForm.deliverySheet.pile?.name
                ? deliverySheetForm.deliverySheet.pile?.name
                : props.pileSelected
                ? props.pileSelected.name
                : i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_pile')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowPileListSelect(true)}
            disabled={props.pileSelected ? true : loggingIsSelected() ? false : true}
          />

          {/**pile Modal select */}
          {showPileListSelect ? (
            <UrstammModalSelect
              testID={'modal_pile_select'}
              visible={showPileListSelect}
              text={{
                textInputTitle: i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_pile'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => setShowPileListSelect(false)}
              listJSX={
                <UrstammList
                  filterMethod={(currentState: string, text: string) => getFilteredPileData(currentState, text)}
                  elementSelected={(pileSelected: any) => {
                    updateDeliverySheetForm('pile', pileSelected.item, true);
                    setShowPileListSelect(false);
                  }}
                  testID={'pile_list'}
                  textForEmptyList={i18n.t('views.pile.pile_not_found')}
                  list={props.pileList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainCardContainerRowCenter,
                    mainTitleTextStyle: UrstammStyleList.cardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  filterBy={undefined}
                  filterList={true}
                  currentState={{
                    icon: state => getIconByLoggingState(state),
                    color: state => getColorByLoggingState(state),
                    text: state => getTextByLoggingState(state)
                  }}
                  showHash={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Sawmill list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_sawmill')}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_sawmill'}
            text={
              deliverySheetForm.deliverySheet.sawmill?.name
                ? deliverySheetForm.deliverySheet.sawmill?.name
                : i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_sawmill')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowSawmillListSelect(true)}
            disabled={false}
          />

          {/**Sawmill Modal select */}
          {showSawmillListSelect ? (
            <UrstammModalSelect
              testID={'modal_sawmill_select'}
              visible={showSawmillListSelect}
              text={{
                textInputTitle: i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_sawmill'),
                textStyle: UrstammStyle.textTitle,
                textInputSubTitle: i18n.t(
                  'views.delivery_sheet.delivery_sheet_registration.attention_sawmill_cannot_be_changed_afterwards'
                ),
                textSubTitleStyle: UrstammStyle.attentionSubTitle
              }}
              closeModal={() => setShowSawmillListSelect(false)}
              listJSX={
                <UrstammList
                  filterMethod={(currentState: string, text: string) => getFilteredData(currentState, text)}
                  elementSelected={(sawmillSelected: any) => {
                    updateDeliverySheetForm('sawmill', sawmillSelected.item, false);
                    setShowSawmillListSelect(false);
                  }}
                  testID={'sawmill_list'}
                  textForEmptyList={i18n.t('views.delivery_sheet.delivery_sheet_registration.sawmill_not_found')}
                  list={rdxSawmillList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainCardContainerRowCenter,
                    mainTitleTextStyle: UrstammStyleList.cardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={undefined}
                  filterBy={undefined}
                  moreItems={props.moreItemsSawmill}
                  filterList={true}
                  showHash={false}
                />
              }
            />
          ) : null}
        </View>

        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammButtonSelect
            testID={'recording_type_button_select'}
            buttonSelectTitle={i18n.t('generics.choose_recording_type')}
            options={deliverySheetRecordingType}
            style={mainGreenButtonSelectActive}
            mandatory={true}
            onSubmit={(data: ButtonSelect) => {
              activeDeliverySheetRecordingType(data.enum);
            }}
          />
        </View>

        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_pile'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitDeliverySheetForm(deliverySheetForm)}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
