import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { setPileList } from '@redux/features/pile/pileSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AnyAction, Dispatch } from 'redux';
import { storePilePhoto } from '../../utils/classes/UrstammUtilityFile';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';
import { customPileResourceApi } from './ApiConfiguration';
import {
  CreatePileCustomRequest,
  DeliverySheetDTO,
  DeliverySheetDTOCurrentStateEnum,
  DeliverySheetDTOCurrentUrstammStateEnum,
  GetPilesByLoggingRequest,
  Pile,
  PileCurrentStateEnum,
  PileWoodTypeEnum,
  UpdatePileCustomRequest
} from './generated';

export class FullAvailablePileResourceApi {
  /**
   * Get all pile list for logging
   * @param rdxPileCompleteList Redux list of Pile
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of piles by logging or redux list if network is not reachable
   */
  static async getPilesByLogging(
    rdxPileCompleteList: Pile[],
    requestParameters: GetPilesByLoggingRequest,
    initOverrides?: RequestInit
  ): Promise<Array<Pile>> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customPileResourceApi.getPilesByLogging(requestParameters, initOverrides);
    let pileList = rdxPileCompleteList.filter(pile => pile.logging?.id == requestParameters.loggingId);
    return sortOfflineList(pileList, requestParameters);
  }

  /**
   * Create a Pile
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createPileCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    setDeliverySheetList: ActionCreatorWithPayload<any, string>,
    requestParameters: CreatePileCustomRequest,
    initOverrides?: RequestInit
  ) {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customPileResourceApi.createPileCustom(requestParameters, initOverrides);
    requestParameters.entityWithFileDTOPile.entity!.id = DateHelper.nowTimestamp();
    const base64 = requestParameters.entityWithFileDTOPile.base64File!;
    if (base64) {
      storePilePhoto(requestParameters.entityWithFileDTOPile.entity!.id, base64);
    }
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTOPile,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.PILE,
        name: requestParameters.entityWithFileDTOPile.entity?.name
      })
    );
    dispatch(
      setPileList([
        {
          automaticallyCreated: requestParameters.entityWithFileDTOPile.entity?.automaticallyCreated,
          comment: requestParameters.entityWithFileDTOPile.entity?.comment,
          creationDate: requestParameters.entityWithFileDTOPile.entity?.creationDate,
          currentState: PileCurrentStateEnum.InProgress,
          estimatedVolume: requestParameters.entityWithFileDTOPile.entity?.estimatedVolume,
          name: requestParameters.entityWithFileDTOPile.entity?.name,
          id: requestParameters.entityWithFileDTOPile.entity?.id,
          latitude: requestParameters.entityWithFileDTOPile.entity?.latitude,
          longitude: requestParameters.entityWithFileDTOPile.entity?.longitude,
          logging: {
            id: requestParameters.entityWithFileDTOPile.entity?.logging?.id,
            name: requestParameters.entityWithFileDTOPile.entity?.logging?.name
          },
          woodType: requestParameters.entityWithFileDTOPile.entity?.woodType,
          otherAssortments: requestParameters.entityWithFileDTOPile.entity?.otherAssortments,
          photoCreationDate: requestParameters.entityWithFileDTOPile.entity?.photoCreationDate,
          pileStates: requestParameters.entityWithFileDTOPile.entity?.pileStates,
          photoFilepath: requestParameters.entityWithFileDTOPile.base64File,
          sumCubage: requestParameters.entityWithFileDTOPile.entity?.sumCubage,
        }
      ])
    );
    requestParameters.entityWithFileDTOPile.base64File = undefined;
    if (requestParameters.entityWithFileDTOPile.entity?.woodType == PileWoodTypeEnum.Timber) {
      let automaticDS: DeliverySheetDTO = FullAvailablePileResourceApi.createAutomaticDS(
        requestParameters.entityWithFileDTOPile.entity
      );
      // Add automatically created DS which will be updated afterwards
      dispatch(
        addOfflineObjectSync({
          entity: automaticDS,
          type: OfflineSyncTypeEnum.INSERT,
          class: OfflineSyncClassEnum.DELIVERYSHEET,
          name: ''
        })
      );
      dispatch(setDeliverySheetList([automaticDS]));
    }
    return requestParameters.entityWithFileDTOPile.entity;
  }

  /**
   * Update a Pile
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async updatePileCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    setDeliverySheetList: ActionCreatorWithPayload<any, string>,
    isNewTimber: boolean,
    requestParameters: UpdatePileCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Pile> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customPileResourceApi.updatePileCustom(requestParameters, initOverrides);
    const base64 = requestParameters.entityWithFileDTOPile.base64File!;
    if (base64) {
      storePilePhoto(requestParameters.entityWithFileDTOPile.entity!.id, base64);
    }
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTOPile,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.PILE,
        name: requestParameters.entityWithFileDTOPile.entity?.name
      })
    );
    dispatch(
      setPileList([
        {
          automaticallyCreated: requestParameters.entityWithFileDTOPile.entity?.automaticallyCreated,
          comment: requestParameters.entityWithFileDTOPile.entity?.comment,
          creationDate: requestParameters.entityWithFileDTOPile.entity?.creationDate,
          currentState: PileCurrentStateEnum.InProgress,
          estimatedVolume: requestParameters.entityWithFileDTOPile.entity?.estimatedVolume,
          name: requestParameters.entityWithFileDTOPile.entity?.name,
          id: requestParameters.entityWithFileDTOPile.entity?.id,
          latitude: requestParameters.entityWithFileDTOPile.entity?.latitude,
          longitude: requestParameters.entityWithFileDTOPile.entity?.longitude,
          logging: {
            id: requestParameters.entityWithFileDTOPile.entity?.logging?.id,
            name: requestParameters.entityWithFileDTOPile.entity?.logging?.name
          },
          woodType: requestParameters.entityWithFileDTOPile.entity?.woodType,
          otherAssortments: requestParameters.entityWithFileDTOPile.entity?.otherAssortments,
          photoCreationDate: requestParameters.entityWithFileDTOPile.entity?.photoCreationDate,
          pileStates: requestParameters.entityWithFileDTOPile.entity?.pileStates,
          photoFilepath: requestParameters.entityWithFileDTOPile.base64File,
          sumCubage: requestParameters.entityWithFileDTOPile.entity?.sumCubage,
        }
      ])
    );
    requestParameters.entityWithFileDTOPile.base64File = undefined;
    if (isNewTimber) {
      let automaticDS: DeliverySheetDTO = FullAvailablePileResourceApi.createAutomaticDS(
        requestParameters.entityWithFileDTOPile.entity!
      );
      // Add automatically created DS which will be updated afterwards
      dispatch(
        addOfflineObjectSync({
          entity: automaticDS,
          type: OfflineSyncTypeEnum.INSERT,
          class: OfflineSyncClassEnum.DELIVERYSHEET,
          name: ''
        })
      );
      dispatch(setDeliverySheetList([automaticDS]));
    }
    return requestParameters.entityWithFileDTOPile.entity!;
  }

  /**
   * Creates a new Delivery Sheet object starting from given Pile
   * @param pile Origin pile
   * @returns The new DS object
   */
  private static createAutomaticDS(pile: Pile): DeliverySheetDTO {
    /**
     * Backend Code
     */
    // new DeliverySheet().automaticallyCreated(true).creationDate(Instant.now()).forestry(pile.getLogging().getCompany())
    //                     .currentState(DeliverySheetStateEnum.IN_PROGRESS_BY_FORESTRY)
    //                     .currentUrstammState(DeliverySheetUrstammStateEnum.IN_PROGRESS)
    return {
      id: DateHelper.nowTimestamp(),
      pile: pile,
      automaticallyCreated: true,
      creationDate: new Date(),
      logging: pile?.logging,
      forestry: pile?.logging?.company,
      currentState: DeliverySheetDTOCurrentStateEnum.InProgressByForestry,
      currentUrstammState: DeliverySheetDTOCurrentUrstammStateEnum.InProgress
    };
  }
}
