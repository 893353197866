import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSortList from '@components/utility-components/modal/UrstammModalSortList';
import { i18n } from '@i18n/i18n';
import { selectProjectFilter, setProjectFilterList } from '@redux/features/filter/projectFilteSlice';
import { RootState } from '@redux/store';
import { customProjectResourceApi } from '@services/apis/ApiConfiguration';
import { PageProject, ProcessorBatch, Project, ProjectCurrentStateEnum, ProjectStateEnumFilterEqualsEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
    getColorByProjectState,
    getIconByProjectState,
    getTextByProjectState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProjectByNameOrUniqueNumber } from '../../../utils/classes/UrstammUtilityFilter';
import { getCardTitleByProject } from '../../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyle, UrstammStyleList } from '../../../utils/styles/UrstammStyle';

export const ProjectListView = (props: {
  navigation: any;
  projectList: Project[];
  moreItems?: () => void;
  refresh?: () => void;
  modalVisible: boolean;
  projectSelected?: (project: Project) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
}) => {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const projectFilter = useSelector((state: RootState) => state.persistedReducer.projectFilter.value);
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.project.sortBy);
  const dispatch = useDispatch();

  useEffect(() => {
    getSlideButtonOptionsProjectCurrentStates();
  }, []);

  /**
   * Function used for generating filter buttons
   */
  const getSlideButtonOptionsProjectCurrentStates = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    let type: SlideButtonOption = {
      id: 'ALL',
      text: i18n.t('utility_components.filter_button.all'),
      key: 'currentUrstammState',
      active: true
    };
    filterList.push(type);
    Object.values(ProjectCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByProjectState(state)!,
        key: 'currentState',
        active: false
      };
      filterList.push(type);
    });
    dispatch(setProjectFilterList(filterList));

    return filterList;
  };

  const setProjectCurrentState = (selected: SlideButtonOption): void => {
    dispatch(selectProjectFilter(selected));
  };

  const [projectSelected, setProjectSelected] = useState<ProcessorBatch>();

  let keys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('views.project.amount_of_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'totalProducts',
      keyId: '1totalProducts',
      // dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      isEnum: true
    },
    {
      title: i18n.t('generics.volume_with_unit') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage'
      // dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' },
    }
  ];

  const getActiveProjectCurrentState = (): SlideButtonOption[] => {
    return projectFilter.filter(val => val.active);
  };

  const getFilteredData = (currentState: string, text: string): Promise<any> => {
    let sortBy =
      rdxSortBy && rdxSortBy.length > 0 ? [rdxSortBy[0].sort?.sortBy + ',' + rdxSortBy[0].sort?.direction] : ['creationDate,desc'];

    return customProjectResourceApi 
      .getPageProjectsForMyCompany({
        sort: sortBy,
        customProjectCriteria: { currentState: currentState == '' || currentState == 'ALL' ? {} : { equals: currentState as ProjectStateEnumFilterEqualsEnum }, name: { equals: text } },
      })
      .then((pageProject: PageProject) => {
        if (pageProject) {
            return pageProject.content;
        }
      })
      .catch(async error => {
        return [];
      });
  };

  return (
    <View style={[UrstammStyle.flex1]}>
      {props.projectList && props.projectList.length > 0 ? (
        <View style={[{ flex: 0.15 }]}>
          <UrstammSlideButton
            testID={'button_status_filter'}
            slideButtonList={projectFilter}
            slideButtonRotatedTitle={i18n.t('utility_components.filter_button.status')}
            buttonPressed={(selected: SlideButtonOption) => setProjectCurrentState(selected)}
          />
        </View>
      ) : null}
      <View style={[{ flex: 0.85 }]}>
        <UrstammList
          filterMethod={(currentState: string, text: string) => getFilteredData(currentState, text)}
          elementSelected={(data: any) => {
            setProjectSelected(data.item), props.projectSelected ? props.projectSelected(data.item) : null;
          }}
          showHash={false}
          testID={'project_list'}
          list={props.projectList}
          textForEmptyList={i18n.t('views.project.projects_not_found')}
          titleByFunction={data => getCardTitleByProject(data)}
          filterBy={getActiveProjectCurrentState()}
          filterList={true}
          filterListFunction={(text, data) => filterProjectByNameOrUniqueNumber(text, data, rdxUserExtendedMe)}
          listStyle={{
            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
            bottomContainerViewStyle: UrstammStyleList.cardSubData,
            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle
          }}
          refresh={props.refresh}
          moreItems={props.moreItems}
          listSubData={keys}
          currentState={{
            icon: state => getIconByProjectState(state),
            color: state => getColorByProjectState(state),
            text: state => getTextByProjectState(state)!
          }}
          getTextForData={(data, key) => {
            switch (key) {
              case 'products':
                return `${[...(data?.values() || [])].length}`;
              default:
                return '';
            }
          }}
        />
        {props.showSortBy ? (
          <UrstammModalSortList
            testID={'modal_sort'}
            showSortBy={props.showSortBy}
            closeShowSortBy={props.closeShowSortBy}
            applyOrder={(orderSelected: ButtonSelect[]) => props.applyOrder(orderSelected)}
            sortBySelected={rdxSortBy}
          />
        ) : null}
      </View>
    </View>
  );
};
