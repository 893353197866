import UrstammInput from '@components/utility-components/input/UrstammInput';
import { i18n } from '@i18n/i18n';
import React from 'react';
import { Modal, StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import { lightGreenBorderWhiteBGButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';
import UrstammButtonBase from '../button/UrstammButtonBase';
import { UrstammIconProps } from '../icon/UrstammIcon';

type ButtonText = {
  left?: string;
  right?: string;
};

export default function UrstammModal(props: Readonly<{
  testID: string;
  visible?: boolean;
  icon?: UrstammIconProps;
  text: string;
  textStyle: StyleProp<TextStyle>;
  buttonText?: ButtonText;
  confirm?: (confirm: boolean) => void;
  textInput?: boolean;
  externalCompanyInput?: boolean;
  onChangeExternalCompany?: (text: string, key: string) => void;
  onChangeText?: (state: any) => void;
  cancel?: any;
}>) {
  return (
    <View style={UrstammStyleModal.centeredView}>
      <Modal animationType="slide" transparent={true} visible={props.visible}>
        <View style={UrstammStyleModal.centeredView}>
          <View style={UrstammStyleModal.modalView}>
            <View style={UrstammStyleModal.textModalContainer}>
              <Text style={props.textStyle}>{props.text}</Text>
            </View>

            {props.textInput && (
              <View style={UrstammStyleModal.textModalContainer}>
                <UrstammInput
                  testID="text_modal"
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeText!(text);
                  }}
                  //placeholder={i18n.t('views.login.insert_your_email_here')}
                />
              </View>
            )}

            {/* Not so good, but now we have to hurry */}
            {props.externalCompanyInput && (<>
              <View style={UrstammStyleModal.textModalContainer}>
                <UrstammInput
                  testID="text_modal_name"
                  placeholder={i18n.t('generics.company.name')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'name');
                  }}
                />
                <UrstammInput
                  testID="text_modal_phoneNumber"
                  placeholder={i18n.t('generics.company.phoneNumber')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'phoneNumber');
                  }}
                />
              </View>

              <View style={UrstammStyleModal.textModalContainer}>
                <UrstammInput
                  testID="text_modal_email"
                  placeholder={i18n.t('generics.company.email')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'email');
                  }}
                />
                <UrstammInput
                  testID="text_modal_streetName"
                  placeholder={i18n.t('generics.company.streetName')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'streetName');
                  }}
                />
              </View>

              <View style={UrstammStyleModal.textModalContainer}>
                <UrstammInput
                  testID="text_modal_streetNumber"
                  placeholder={i18n.t('generics.company.streetNumber')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'streetNumber');
                  }}
                />
                <UrstammInput
                  testID="text_modal_city"
                  placeholder={i18n.t('generics.company.city')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'city');
                  }}
                />
              </View>

              <View style={UrstammStyleModal.textModalContainer}>
                <UrstammInput
                  testID="text_modal_zipCode"
                  placeholder={i18n.t('generics.company.zipCode')}
                  textInputTitle={' '}
                  style={{
                    containerViewStyle: UrstammStyleContainer.inputContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  onChangeText={(text: string) => {
                    props.onChangeExternalCompany!(text, 'zipCode');
                  }}
                />
              </View>
            </>
            )}

            {props.buttonText && <View style={styles.buttonMainContainer}>
              <View style={UrstammStyleModal.buttonModalContainer}>
                {/**Left */}
                {props.buttonText.left && props.cancel && (
                  <View>
                    <UrstammButtonBase
                      testID={'left-button'}
                      text={props.buttonText.left}
                      style={lightGreenBorderWhiteBGButton}
                      onSubmit={props.cancel}
                    />
                  </View>
                )}
                {props.buttonText.left && !props.cancel && (
                  <View>
                    <UrstammButtonBase
                      testID={'left-button'}
                      text={props.buttonText.left}
                      style={lightGreenBorderWhiteBGButton}
                      onSubmit={() => props.confirm?.(false)}
                    />
                  </View>
                )}
                {/**Right */}
                <View>
                  <UrstammButtonBase
                    testID={'right-button'}
                    text={props.buttonText.right}
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => props.confirm?.(true)}
                  />
                </View>
              </View>
            </View>}
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonMainContainer: {
    height: 80,
    minWidth: 200,
    marginTop: 20
  }
});
