/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { Batch } from './Batch';
import {
    BatchFromJSON,
    BatchToJSON
} from './Batch';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyToJSON
} from './Company';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketToJSON
} from './Packet';

/**
 * 
 * @export
 * @interface ShippingNoteDTO
 */
export interface ShippingNoteDTO {
    /**
     * 
     * @type {number}
     * @memberof ShippingNoteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteDTO
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ShippingNoteDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ShippingNoteDTO
     */
    sumCubage?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteDTO
     */
    currentState?: ShippingNoteDTOCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ShippingNoteDTO
     */
    uuid?: string;
    /**
     * 
     * @type {Company}
     * @memberof ShippingNoteDTO
     */
    processor?: Company;
    /**
     * 
     * @type {Company}
     * @memberof ShippingNoteDTO
     */
    sawmill?: Company;
    /**
     * 
     * @type {Batch}
     * @memberof ShippingNoteDTO
     */
    batch?: Batch;
    /**
     * 
     * @type {Array<Packet>}
     * @memberof ShippingNoteDTO
     */
    packets?: Array<Packet>;
    amountOfPackets?: number;
}


/**
 * @export
 */
export const ShippingNoteDTOCurrentStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type ShippingNoteDTOCurrentStateEnum = typeof ShippingNoteDTOCurrentStateEnum[keyof typeof ShippingNoteDTOCurrentStateEnum];


/**
 * Check if a given object implements the ShippingNoteDTO interface.
 */
export function instanceOfShippingNoteDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShippingNoteDTOFromJSON(json: any): ShippingNoteDTO {
    return ShippingNoteDTOFromJSONTyped(json, false);
}

export function ShippingNoteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingNoteDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'processor': !exists(json, 'processor') ? undefined : CompanyFromJSON(json['processor']),
        'sawmill': !exists(json, 'sawmill') ? undefined : CompanyFromJSON(json['sawmill']),
        'batch': !exists(json, 'batch') ? undefined : BatchFromJSON(json['batch']),
        'packets': !exists(json, 'packets') ? undefined : ((json['packets'] as Array<any>).map(PacketFromJSON)),
        'amountOfPackets': !exists(json, 'amountOfPackets') ? undefined : json['amountOfPackets'],
    };
}

export function ShippingNoteDTOToJSON(value?: ShippingNoteDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'currentState': value.currentState,
        'uuid': value.uuid,
        'processor': CompanyToJSON(value.processor),
        'sawmill': CompanyToJSON(value.sawmill),
        'batch': BatchToJSON(value.batch),
        'packets': value.packets === undefined ? undefined : ((value.packets as Array<any>).map(PacketToJSON)),
    };
}

