import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { AlertHelper } from '@helpers/AlertHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
//import { setProductList } from '@redux/features/product/productSlice';
import { RootState } from '@redux/store';
import {
  Batch,
  Company,
  ProcessorBatch,
  ProcessorShippingNote,
  ProcessorShippingNoteDTO,
  ProcessorShippingNoteDTOCurrentStateEnum,
  Product,
  ProductDTO,
  UserExtended
} from '@services/apis/generated';
import { ExternalCompany } from '@services/apis/generated/models/ExternalCompany';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByProcessorBatchState,
  getColorByProcessorShippingNoteState,
  getColorByProductState,
  getIconByProcessorBatchState,
  getIconByProcessorShippingNoteState,
  getTextByProcessorBatchState,
  getTextByProcessorShippingNoteState,
  getTextByProductState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  getMandatorySymbol,
  idFromProcessorShippingNote,
  isCompanyMock,
  loggedUserIsAProcessor,
  loggedUserIsAProjectmanager,
  processorShippingNoteIsClosed
} from '../../../utils/classes/UrstammUtilityFunctions';
import { buildProcessorShippingNotePdfDownloadUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import { CertificationProps, ProductProps } from '../product/ProductRegistrationView';
import { ProcessorShippingNoteForm } from './ProcessorShippingNoteRegistrationView';

interface ProcessorBatchProps {
  processorBatchList: any[];
  processorBatchSelected: (data: any) => void;
  resetProcessorBatchSelected?: () => void;
  processorBatchSelectedID: (processorBatch: ProcessorBatch) => void;
  moreItems: () => void;
}
interface ProcessorProps {
  getAll: Function;
  processorList: any[];
  processorSelectedID: (processor: Company) => void;
}

interface ProductListProps {
  productSelected: (product?: Product) => void;
}

export default function ProcessorShippingNoteDetailsView(props: {
  updateState: (processorShippingNoteDTO: ProcessorShippingNoteDTO, accepted: boolean) => void;
  navigation: any;
  processorShippingNoteSelectedDTO: ProcessorShippingNoteDTO;
  customProductList: any; // (selected: boolean)
  moreItems: () => void;
  userExtendedMe?: UserExtended;
  productSelected: (data: any) => void;
  updateProcessorShippingNote: (processorShippingNoteDTO: ProcessorShippingNoteDTO) => void;
  resetProductSelected?: () => void;
  confirmProductSelected?: () => void;
  processorShippingNoteList: ProcessorShippingNote[];
  processorBatchProps: ProcessorBatchProps;
  processorProps: ProcessorProps;
  productListProps?: ProductListProps;
  //applyOrder: (orderSelected: ButtonSelect[]) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  processorBatchList: ProcessorBatch[];
  processorList: Company[];
  certificationProps?: CertificationProps;
  incomingProductProps?: ProductProps;
  outgoing: boolean;
  closingProcessorShippingNote: (
    processorShippingNoteDTO: ProcessorShippingNoteDTO,
    externalCompany?: ExternalCompany
  ) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);

  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [processorShippingNoteSelected, setProcessorShippingNoteSelected] = useState<ProcessorShippingNoteDTO>();
  const [externalCompany, setExternalCompany] = useState<ExternalCompany>({});
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [openCloseProcessorShippingNoteModal, setOpenCloseProcessorShippingNoteModal] = useState<boolean>(false);
  const [
    openClosingProcessorShippingNoteWithExternalCompanyModal,
    setOpenClosingProcessorShippingNoteWithExternalCompanyModal
  ] = useState<boolean>(false);
  const [openCloseAcceptProcessorShippingNoteModal, setOpenCloseAcceptProcessorShippingNoteModal] =
    useState<boolean>(false);
  const [openCloseRejectProcessorShippingNoteModal, setOpenCloseRejectProcessorShippingNoteModal] =
    useState<boolean>(false);
  const [productList, setProductList] = useStateWithCallbackLazy<any>(() => {});
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [showProductSelect, setShowProductSelect] = useState<boolean>(false);
  const [showBatchModalSelect, setShowBatchModalSelect] = useState<boolean>(false);

  const [processorShippingNoteForm, setProcessorShippingNoteForm] = useStateWithCallbackLazy<ProcessorShippingNoteForm>(
    {
      processorShippingNote: {
        name: '',
        creationDate: new Date(),
        currentState: ProcessorShippingNoteDTOCurrentStateEnum.InProgress,
        comment: ''
      }
    }
  );

  let dsKeys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataContainerViewStyle: { flexBasis: '100%' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataContainerViewStyle: { flexBasis: '100%' },
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center' },
      truncateMaxLength: 250
    }
  ];

  let pkKeys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('views.processor_batch.sum_volume_products') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage'
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    },
    {
      title: i18n.t('generics.sender') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sender',
      keyId: '1sender',
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      truncateMaxLength: 16,
      isEnum: true
    },
    {
      title: i18n.t('generics.receiver') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'receiver',
      keyId: '1receiver',
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      truncateMaxLength: 16,
      isEnum: true
    }
  ];

  let processorBatchKeys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    if (props.processorShippingNoteSelectedDTO) {
      setProcessorShippingNoteSelected(props.processorShippingNoteSelectedDTO);
      setProcessorShippingNoteForm(
        {
          processorShippingNote: {
            name: props.processorShippingNoteSelectedDTO.name,
            creationDate: props.processorShippingNoteSelectedDTO.creationDate,
            comment: props.processorShippingNoteSelectedDTO.comment,
            outgoingBatch: props.processorShippingNoteSelectedDTO?.outgoingBatch,
            processor: props.processorShippingNoteSelectedDTO?.processor,
            incomingProducts: props.processorShippingNoteSelectedDTO?.incomingProducts
          }
        },
        updatedProcessorShippingNoteForm => {
          saveButtonDisabled(errors, updatedProcessorShippingNoteForm);
        }
      );
    }
  }, [props.processorShippingNoteSelectedDTO]);

  useEffect(() => {
    if (props.customProductList && props.customProductList.length > 0)
      setProductList(props.customProductList, updatedProductList => {
        saveButtonDisabled(errors, updatedProductList);
      });
  }, [props.customProductList]);

  useEffect(() => {
    if (processorShippingNoteForm.processorShippingNote.outgoingBatch) {
    }
  }, [processorShippingNoteForm.processorShippingNote.outgoingBatch]);

  /**
   *
   * @param key
   * @param value
   */
  const updateProcessorShippingNoteForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, processorShippingNoteForm, 'processorShippingNote');
    setProcessorShippingNoteForm(
      { processorShippingNote: form['processorShippingNote'] },
      mustValidate
        ? updatedProcessorShippingNoteForm => validate(value, key, updatedProcessorShippingNoteForm)
        : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ProcessorShippingNoteForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedProductList: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated)) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const downloadPdf = async () => {
    let documentUrl = await buildProcessorShippingNotePdfDownloadUrl(props.processorShippingNoteSelectedDTO);
    if (documentUrl) {
      Linking.canOpenURL(documentUrl).then(supported => {
        if (supported) {
          Linking.openURL(documentUrl);
        } else {
          AlertHelper.showSimpleErrorAlert();
        }
      });
    }
  };

  const updateProcessor = (processor: Company) => {
    // if (processor.id == -200) {
    //   setShowProcessorModalSelect(false);
    //   return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    // }
    //processorShippingNoteForm.processorShippingNote.processorCustomName = '';
    updateProcessorShippingNoteForm('processor', processor);
    props.processorProps.processorSelectedID(processor);
    return setShowProcessorModalSelect(false);
  };

  const updateBatch = (batch: Batch) => {
    updateProcessorShippingNoteForm('outgoingBatch', batch);
    props.processorBatchProps.processorBatchSelectedID(batch);
    return setShowBatchModalSelect(false);
  };

  const getReceiverProcessorShippingNoteProcessorName = (
    processorShippingNote?: ProcessorShippingNote | ProcessorShippingNoteDTO
  ): string => {
    if (!processorShippingNote)
      return i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_processor');
    if (processorShippingNote.processor && !isCompanyMock(processorShippingNote.processor))
      return processorShippingNote.processor.name!;
    else if (processorShippingNote.processor && isCompanyMock(processorShippingNote.processor))
      return i18n.t('views.processor_shipping_note.no_urstamm_processor');
    else return i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_processor');
  };

  const getSenderProcessorShippingNoteProcessorName = (
    processorShippingNote?: ProcessorShippingNote | ProcessorShippingNoteDTO
  ): string => {
    if (!processorShippingNote) return '--';
    if (processorShippingNote.creator && !isCompanyMock(processorShippingNote.creator))
      return processorShippingNote.creator.name!;
    else if (processorShippingNote.creator && isCompanyMock(processorShippingNote.creator))
      return i18n.t('views.processor_shipping_note.no_urstamm_processor');
    else return i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_processor');
  };

  const getReceiverProductProcessorName = (product?: Product | ProductDTO): string => {
    if (!product) return '--';
    if (!props.outgoing) {
      // incoming ?
      return getReceiverProcessorShippingNoteProcessorName(processorShippingNoteSelected);
    } else if (product.processorCustomName) {
      return product.processorCustomName;
    } else if (product.processor && !isCompanyMock(product.processor)) {
      return product.processor.name!;
    } else if (product.processor && isCompanyMock(product.processor)) {
      return i18n.t('views.processor_shipping_note.no_urstamm_processor');
    } else return '--';
  };

  const getSenderProductProcessorName = (
    processorShippingNoteSelected?: ProcessorShippingNote | ProcessorShippingNoteDTO
  ): string => {
    if (!processorShippingNoteSelected) return '--';
    if (processorShippingNoteSelected.creator && !isCompanyMock(processorShippingNoteSelected.creator))
      return processorShippingNoteSelected.creator.name!;
    else if (processorShippingNoteSelected.creator && isCompanyMock(processorShippingNoteSelected.creator))
      return i18n.t('views.processor_shipping_note.no_urstamm_processor');
    else return '--';
  };

  const isProductFieldHiddenFunction = (item, key) => {
    const { sumCubage = null } = item;
    const sumCubageDeps = ['processor', 'productSpecies', 'productType'];
    if (NumberHelper.roundWithThreeDecimals(sumCubage) === 0 && sumCubageDeps.includes(key)) {
      return true;
    }
    return false;
  };

  const getFilteredData = (currentState: string, text: string): any => {
    return processorShippingNoteSelected?.incomingProducts?.filter(val =>
      val.name.toLowerCase().includes(text.toLowerCase())
    );
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/**Status and creation date */}
        <View style={UrstammStyleLayout.formElementContainer}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {processorShippingNoteSelected?.currentState ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {processorShippingNoteSelected?.currentState &&
                  getIconByProcessorShippingNoteState(processorShippingNoteSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByProcessorShippingNoteState(processorShippingNoteSelected.currentState)
                  ]}>
                  {getTextByProcessorShippingNoteState(processorShippingNoteSelected.currentState!, !props.outgoing)}
                </Text>
              </View>
            ) : null}
            {processorShippingNoteSelected?.creationDate ? (
              <View style={UrstammStyle.flexRow}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creationDate') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(processorShippingNoteSelected.creationDate).format('DD/MM/yyyy')}
                </Text>
              </View>
            ) : null}
          </View>
          <View
            style={[
              UrstammStyleContainer.row1SpaceBetween,
              UrstammStyle.wrap,
              processorShippingNoteSelected?.outgoingBatch ? {} : UrstammStyle.flexEndX
            ]}>
            {processorShippingNoteSelected?.id || processorShippingNoteSelected?.referenceShippingNote?.id ? (
              <View style={[UrstammStyle.flexRow, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.id') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {String(idFromProcessorShippingNote(processorShippingNoteSelected))}
                </Text>
              </View>
            ) : null}
            {processorShippingNoteSelected?.outgoingBatch ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.wrap, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.batch') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {getCardTitleByProcessorBatchOrProduct(processorShippingNoteSelected?.outgoingBatch)}
                </Text>
              </View>
            ) : null}
          </View>

          <View
            style={[
              UrstammStyleContainer.row1SpaceBetween,
              UrstammStyle.wrap,
              processorShippingNoteSelected?.processor ? {} : UrstammStyle.flexStartX
            ]}>
            {processorShippingNoteSelected?.creator ? (
              <View style={[UrstammStyle.flexRow, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.sender') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {getSenderProcessorShippingNoteProcessorName(processorShippingNoteSelected)}
                </Text>
              </View>
            ) : null}

            {processorShippingNoteSelected?.processor ? (
              <View style={[UrstammStyle.flexRow, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.receiver') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {getReceiverProcessorShippingNoteProcessorName(processorShippingNoteSelected)}
                </Text>
              </View>
            ) : null}
          </View>

          <View style={[UrstammStyleLayout.formElementContainer]}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {processorShippingNoteSelected?.incomingProducts ? (
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amount_of_product') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{processorShippingNoteSelected?.incomingProducts?.length}</Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>

        <View style={UrstammStyle.divider} />

        {/* Only for receiver (processor or project manager) */}
        {props.processorShippingNoteSelectedDTO &&
        !props.outgoing &&
        props.processorShippingNoteSelectedDTO.currentState == ProcessorShippingNoteDTOCurrentStateEnum.Sent &&
        (loggedUserIsAProcessor(rdxUserExtendedMe) || loggedUserIsAProjectmanager(rdxUserExtendedMe)) ? (
          <>
            <View style={[{ marginTop: 8 }]}>
              <UrstammButtonBase
                testID={'accept_shipping_note'}
                text={i18n.t('views.processor_shipping_note.accept_shipping_note')}
                style={mainGreenButton}
                onSubmit={() =>
                  props.processorShippingNoteSelectedDTO.processor != null
                    ? setOpenCloseAcceptProcessorShippingNoteModal(true)
                    : AlertHelper.showSimpleAlert('Error', 'cannot_accept_shipping_note_without_processor')
                }
              />
            </View>
            <View style={[{ marginTop: 8 }]}>
              <UrstammButtonBase
                testID={'reject_shipping_note'}
                text={i18n.t('views.processor_shipping_note.reject_shipping_note')}
                style={mainGreenButton}
                onSubmit={() =>
                  props.processorShippingNoteSelectedDTO.processor != null
                    ? setOpenCloseRejectProcessorShippingNoteModal(true)
                    : AlertHelper.showSimpleAlert('Error', 'cannot_reject_shipping_note_without_processor')
                }
              />
            </View>
          </>
        ) : null}

        {/* TODO: Only for creator */}
        {props.processorShippingNoteSelectedDTO &&
        props.outgoing &&
        props.processorShippingNoteSelectedDTO.currentState == ProcessorShippingNoteDTOCurrentStateEnum.InProgress &&
        loggedUserIsAProcessor(rdxUserExtendedMe) ? (
          <View style={[{ marginTop: 8 }]}>
            <UrstammButtonBase
              testID={'send_shipping_note'}
              text={i18n.t('views.processor_shipping_note.send_shipping_note')}
              style={mainGreenButton}
              onSubmit={() =>
                props.processorShippingNoteSelectedDTO.processor != null
                  ? setOpenCloseProcessorShippingNoteModal(true)
                  : AlertHelper.showSimpleAlert('Error', 'cannot_send_shipping_note_without_processor')
              }
            />
          </View>
        ) : null}

        {/** Download pdf Shipping note */}
        {props.processorShippingNoteSelectedDTO &&
        props.processorShippingNoteSelectedDTO?.currentState != ProcessorShippingNoteDTOCurrentStateEnum.InProgress &&
        (loggedUserIsAProcessor(rdxUserExtendedMe) || loggedUserIsAProjectmanager(rdxUserExtendedMe)) ? (
          <View style={{ marginTop: 8 }}>
            <UrstammButtonBase
              testID={'download_shipping_note_pdf'}
              text={i18n.t('generics.download_pdf')}
              style={mainGreenButton}
              onSubmit={downloadPdf}
            />
          </View>
        ) : null}

        <View style={{ marginTop: 8 }}>
          {/**Edit Product Collapsible */}
          {props.outgoing ? (
            <>
              <View
                style={[
                  UrstammStyleButton.smallBaseButtonStructure,
                  { backgroundColor: ColorTheme.yellow },
                  UrstammStyle.baseShadow
                ]}>
                <View style={UrstammStyleCollapsible.selectMainContainer}>
                  <TouchableOpacity
                    style={UrstammStyleCollapsible.selectCollapsible}
                    onPress={() => setCollapsed(!collapsed)}>
                    <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                    <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
                  </TouchableOpacity>
                </View>
              </View>

              <View style={[{ marginTop: 4 }]}>
                <Collapsible
                  duration={500}
                  easing={'easeInBounce'}
                  collapsed={collapsed}
                  style={UrstammStyleCollapsible.containerCollapsible}>
                  <ScrollView
                    keyboardShouldPersistTaps="always"
                    contentContainerStyle={[
                      {
                        flexGrow: 1,
                        flexDirection: 'column',
                        paddingBottom: 40
                      }
                    ]}>
                    {/** ProcessorShippingNote name */}
                    <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
                      <UrstammInput
                        testID="shipping_note_name"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={
                          processorShippingNoteForm.processorShippingNote.name
                            ? processorShippingNoteForm.processorShippingNote.name
                            : ''
                        }
                        onChangeText={(text: string) => {
                          updateProcessorShippingNoteForm('name', text, true);
                        }}
                        placeholder={i18n.t(
                          'views.processor_shipping_note.processor_shipping_note_registration.insert_shipping_note_name_here'
                        )}
                        textInputTitle={i18n.t(
                          'views.processor_shipping_note.processor_shipping_note_registration.shipping_note_name'
                        )}
                        mandatory={true}
                        editable={!processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO)}
                      />

                      {/**Validator */}
                      {errors['name'] && errors['name'].length > 0
                        ? errors['name'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>

                    {/** ProcessorShippingNote creation date (preset: today) */}
                    <View style={UrstammStyleLayout.formElementContainerGrw1}>
                      <UrstammDatePicker
                        testID={'shipping_note_date'}
                        containerStyle={UrstammStyleContainer.inputContainer}
                        fontStyle={UrstammStyle.formBaseLDarkGrey}
                        textInputTitle={i18n.t('generics.date')}
                        onChange={(dateTime: string) => updateProcessorShippingNoteForm('creationDate', dateTime)}
                        icon={{
                          showIcon: true,
                          iconName: 'calendar',
                          iconColor: ColorTheme.lightGreen
                        }}
                        defaultValue={processorShippingNoteSelected?.creationDate}
                        placeholder={i18n.t(
                          'views.processor_shipping_note.processor_shipping_note_registration.insert_shipping_note_date_here'
                        )}
                        mandatory={true}
                        mode={'date'}
                        disabled={processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO)}
                      />
                    </View>

                    {/** Batch list */}
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor_batch')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_product_type'}
                        text={
                          processorShippingNoteForm.processorShippingNote.outgoingBatch
                            ? processorShippingNoteForm.processorShippingNote.outgoingBatch.name
                            : i18n.t('views.processor_shipping_note.processor_shipping_note_registration.choose_batch')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowBatchModalSelect(true)}
                        disabled={processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO)}
                      />

                      {/**Product processorBatch  */}
                      {showBatchModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_shipping_note_company_processorBatch'}
                          visible={showBatchModalSelect}
                          text={{
                            textInputTitle: i18n.t(
                              'views.processor_shipping_note.processor_shipping_note_registration.choose_batch'
                            ),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.shipping_note_selected')+ ': ' + getCounterByProductSelected()
                          }}
                          closeModal={() => {
                            setShowBatchModalSelect(false);
                          }}
                          confirmButton={{
                            enabled: false,
                            text: i18n.t('views.processor_shipping_note.no_urstamm_processor_batch'),
                            submitValue: () => {}
                          }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(processorBatch: any) => {
                                updateBatch(processorBatch.item);
                              }}
                              showHash={false}
                              testID={'processorBatch_list'}
                              titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                              textForEmptyList={i18n.t('views.processor_shipping_note.processor_batches_not_found')}
                              listSubData={processorBatchKeys}
                              list={props.processorBatchList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              currentState={{
                                icon: state => getIconByProcessorBatchState(state),
                                color: state => getColorByProcessorBatchState(state),
                                text: state => getTextByProcessorBatchState(state)!
                              }}
                              //listSubData={keys}
                              filterList={true}
                              multipleSelection={false}
                            />
                          }
                        />
                      ) : null}
                    </View>

                    {/** Processor list */}
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_processor'}
                        text={getReceiverProcessorShippingNoteProcessorName(
                          processorShippingNoteForm?.processorShippingNote
                        )}
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowProcessorModalSelect(true)}
                        disabled={processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO)}
                      />

                      {/**Product processor  */}
                      {showProcessorModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_shipping_note_company_processor'}
                          visible={showProcessorModalSelect}
                          text={{
                            textInputTitle: i18n.t(
                              'views.processor_shipping_note.processor_shipping_note_registration.choose_processor'
                            ),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.shipping_note_selected')+ ': ' + getCounterByProductSelected()
                          }}
                          closeModal={() => {
                            setShowProcessorModalSelect(false);
                          }}
                          confirmButton={{
                            enabled: false,
                            text: i18n.t('views.processor_shipping_note.no_urstamm_processor'),
                            submitValue: () => {}
                          }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(processor: any) => {
                                updateProcessor(processor.item);
                              }}
                              showHash={false}
                              testID={'processor_list'}
                              textForEmptyList={i18n.t('views.processor_shipping_note.processors_not_found')}
                              list={props.processorList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              filterList={true}
                              multipleSelection={false}
                            />
                          }
                        />
                      ) : null}
                    </View>

                    {/** Products list */}
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t(
                            'views.processor_shipping_note.processor_shipping_note_registration.choose_products'
                          ) + getMandatorySymbol(false)}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_product'}
                        text={i18n.t(
                          'views.processor_shipping_note.processor_shipping_note_registration.choose_products'
                        )}
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowProductSelect(true)}
                        counter={getCounterBySelected(props.customProductList)}
                        disabled={processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO)}
                      />

                      {/**Products multiple select */}
                      {showProductSelect ? (
                        <UrstammModalSelect
                          testID={'modal_processorShippingNote_multiple_select'}
                          visible={showProductSelect}
                          text={{
                            textInputTitle: i18n.t(
                              'views.processor_shipping_note.processor_shipping_note_registration.choose_products'
                            ),
                            textStyle: UrstammStyle.textTitle
                          }}
                          closeModal={() => {
                            setShowProductSelect(false),
                              props.resetProductSelected ? props.resetProductSelected() : null;
                          }}
                          confirmButton={{
                            enabled: true,
                            text: i18n.t('generics.save'),
                            disabled: props.customProductList?.length == 0 ? true : false,
                            submitValue: () => {
                              setShowProductSelect(false);
                              props.confirmProductSelected ? props.confirmProductSelected() : null;
                            }
                          }}
                          listJSX={
                            <UrstammList
                              elementSelected={(productSelected: any) => {
                                props.productSelected(productSelected),
                                  saveButtonDisabled(errors, processorShippingNoteForm);
                              }}
                              showHash={false}
                              testID={'product_list'}
                              textForEmptyList={i18n.t('views.product.products_not_found')}
                              titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                              list={props.customProductList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              listSubData={dsKeys}
                              moreItems={props.moreItems}
                              filterList={true}
                              filterListFunction={(text, data) =>
                                filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor(
                                  text,
                                  data,
                                  props.userExtendedMe!
                                )
                              }
                              multipleSelection={true}
                              currentState={{
                                //icon: (state) => getIconByLoggingState(state),
                                color: state => getColorByProductState(state),
                                text: state => getTextByProductState(state)
                              }}
                              titleKey={'name'}
                              getTextForData={(data, key) => getTextByProductState(data)}
                            />
                          }
                        />
                      ) : null}
                    </View>

                    {/** Comment */}
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="processorShippingNote_comment"
                        style={{
                          containerViewStyle: UrstammStyleContainer.textareaContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={
                          processorShippingNoteForm.processorShippingNote.comment
                            ? processorShippingNoteForm.processorShippingNote.comment
                            : ''
                        }
                        onChangeText={(text: string) => updateProcessorShippingNoteForm('comment', text, false)}
                        placeholder={i18n.t('generics.insert_comment_here')}
                        textInputTitle={i18n.t('generics.comment')}
                        mandatory={false}
                        multiline={true}
                        editable={!processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO)}
                      />
                    </View>

                    {!processorShippingNoteIsClosed(props.processorShippingNoteSelectedDTO) ? (
                      <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                        <UrstammButtonBase
                          testID={'save_product'}
                          text={i18n.t('generics.save')}
                          style={mainGreenButton}
                          onSubmit={() =>
                            props.updateProcessorShippingNote(processorShippingNoteForm.processorShippingNote)
                          }
                          disabled={saveDisabled}
                        />
                      </View>
                    ) : null}
                  </ScrollView>
                </Collapsible>
              </View>
            </>
          ) : null}

          {openCloseProcessorShippingNoteModal && (
            <UrstammModal
              testID={'send_shipping_note_modal'}
              text={i18n.t('views.processor_shipping_note.are_you_sure_want_send_shipping_note')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseProcessorShippingNoteModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              confirm={(confirmed: boolean) => {
                setOpenCloseProcessorShippingNoteModal(false);
                if (
                  confirmed &&
                  props.processorShippingNoteSelectedDTO.processor != null &&
                  !isCompanyMock(props.processorShippingNoteSelectedDTO.processor)
                ) {
                  props.closingProcessorShippingNote(props.processorShippingNoteSelectedDTO);
                } else if (confirmed) {
                  setExternalCompany({});
                  setOpenClosingProcessorShippingNoteWithExternalCompanyModal(true);
                }
              }}
            />
          )}

          {openClosingProcessorShippingNoteWithExternalCompanyModal && (
            <UrstammModal
              testID={'pdf_modal'}
              text={i18n.t('generics.processor')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openClosingProcessorShippingNoteWithExternalCompanyModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              externalCompanyInput={true}
              onChangeExternalCompany={(text, key) => {
                externalCompany[key] = text;
                setExternalCompany(externalCompany);
              }}
              confirm={(confirmed: boolean) => {
                confirmed
                  ? props.closingProcessorShippingNote(props.processorShippingNoteSelectedDTO, externalCompany)
                  : null,
                  setOpenClosingProcessorShippingNoteWithExternalCompanyModal(false);
              }}
            />
          )}

          {openCloseAcceptProcessorShippingNoteModal && (
            <UrstammModal
              testID={'accept_shipping_note_modal'}
              text={i18n.t('views.processor_shipping_note.are_you_sure_want_accept_shipping_note')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseAcceptProcessorShippingNoteModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              confirm={(confirmed: boolean) => {
                setOpenCloseAcceptProcessorShippingNoteModal(false);
                if (confirmed) {
                  props.updateState(props.processorShippingNoteSelectedDTO, true);
                }
              }}
            />
          )}

          {openCloseRejectProcessorShippingNoteModal && (
            <UrstammModal
              testID={'reject_shipping_note_modal'}
              text={i18n.t('views.processor_shipping_note.are_you_sure_want_reject_shipping_note')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseRejectProcessorShippingNoteModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              confirm={(confirmed: boolean) => {
                setOpenCloseRejectProcessorShippingNoteModal(false);
                if (confirmed) {
                  props.updateState(props.processorShippingNoteSelectedDTO, false);
                }
              }}
            />
          )}
        </View>

        {/** Product list view */}
        {processorShippingNoteSelected ? (
          <View style={UrstammStyle.flex1}>
            <UrstammList
              filterMethod={(currentState: string, text: string) => getFilteredData(currentState, text)}
              elementSelected={(productSelected: any) => {
                props.productListProps?.productSelected?.(productSelected?.item);
              }}
              showHash={false}
              testID={'product_list'}
              titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
              textForEmptyList={i18n.t('views.product.products_not_found')}
              list={processorShippingNoteSelected.incomingProducts || []}
              listStyle={{
                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                paddingBottom: 4
              }}
              listSubData={pkKeys}
              isFieldHiddenFunction={isProductFieldHiddenFunction}
              filterList={true}
              filterListFunction={(text, data) =>
                filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, props.userExtendedMe!)
              }
              getTextForData={(data, key) => {
                if (key === 'sender') return getSenderProductProcessorName(processorShippingNoteSelected);
                return '';
              }}
              getTextForFullData={(data, key) => {
                if (key === 'receiver') return getReceiverProductProcessorName(data);
                return '';
              }}
              currentState={{
                //icon: (state) => getIconByLoggingState(state),
                color: state => getColorByProductState(state),
                text: state => getTextByProductState(state)
              }}
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}
